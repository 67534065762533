import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  ui: {
    SET_APP_LOADING: SYNC,
    SET_APP_INIT_STATUS: SYNC,
    SET_SEARCH_TEXT: SYNC,
    SET_CURRENT_PAGE_ITEM: SYNC,
    SEARCH: ASYNC,
    SEARCH_ALL: ASYNC,
    FETCH_SEARCH_FILTERS: ASYNC,
    FETCH_TREE_DATA: ASYNC,
    CLEAR_SEARCH_RESULTS: SYNC,
    CLOSE_MESSAGE: SYNC,
    CLOSE_ANONYMOUS_MESSAGE: SYNC,
    CLEAR_MESSAGES: SYNC,
    SET_FORM_MODE: SYNC,
    SET_CURRENT_STEP: SYNC,
    SET_CURRENT_INDEX: SYNC,
    TOGGLE_PROCEDURE_DRAWER: SYNC,
    TOGGLE_DISPLAY_DRAFT: SYNC,
    TOGGLE_META_DRAWER: SYNC,
    TOGGLE_AFFIX_DRAWER: SYNC,
    TOGGLE_COMPARE_VIEW: SYNC,
    SET_SHOW_LOCAL: SYNC,
    RESIZE_MENU: SYNC,
    RESTORE_MENU_SIZE: SYNC,
    FORM_MODAL: {
      OPEN: SYNC,
      CLOSE: SYNC,
    },
    TOGGLE_LOCAL_AMENDMENT_MODAL: SYNC,
    ANALYTICS: {
      TRACK_EVENT: SYNC,
      TRACK_PAGE_NOT_FOUND: SYNC,
      TRACK_EXCEPTION: SYNC,
      TRACK_PAGE_RENDER: SYNC,
    },
    CONNECTION: { CHANGE: SYNC },
  },
})

export default types.ui
