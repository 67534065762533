import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  refiner: {
    FETCH_USER_PROFILE: ASYNC,
    FETCH_CONFIGURATOR: ASYNC,
    FETCH_ROLES: ASYNC,
    FETCH_TAG_A: ASYNC,
    FETCH_TAG_B: ASYNC,
    FETCH_TAG_C: ASYNC,
    SET_TAG_A: SYNC,
    SET_TAG_B: SYNC,
    SET_TAG_C: SYNC,
    SET_ROLE: SYNC,
    TOGGLE: SYNC,
    SET_REFINER: SYNC,
    RESET: SYNC,
    MINIMIZE: SYNC,
  },
})

export default types.refiner
