import { DEFAULT_LANGUAGE, TREES } from "src/globals"

export const getCurrentSlug = (locale: string) => getPathnameWithoutLocale(locale).substring(1)

export const getCurrentTree = (locale: string) =>
  getTreeFromPathname(getPathnameWithoutLocale(locale))

export const getPathnameWithoutLocale = (locale: string) => {
  if (locale === DEFAULT_LANGUAGE) return window.location.pathname

  const splitPathname = window.location.pathname.split("/")
  splitPathname.splice(1, 1) // remove locale from pathname

  return splitPathname.join("/")
}

export const getTreeFromPathname = (pathname: string) => {
  const [, treeFromPath] = pathname.split("/")

  switch (treeFromPath) {
    case TREES.PROCESSES:
    case TREES.COMMERCIAL_LEGAL:
    case TREES.POLICIES_STANDARDS:
      return treeFromPath
    default:
      return null
  }
}

export const getTreeFromSlug = (slug: string) => {
  if (!slug) return null

  const [treeFromSlug] = slug.split("/")

  switch (treeFromSlug) {
    case TREES.PROCESSES:
    case TREES.COMMERCIAL_LEGAL:
    case TREES.POLICIES_STANDARDS:
      return treeFromSlug
    default:
      return null
  }
}
