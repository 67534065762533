import { produce } from "immer"
import uiTypes from "../ui/types"

export type AnalyticsState = {
  exception: Error | null
}

export const initialState: AnalyticsState = Object.freeze({
  exception: null
})

export default function reducer(state = initialState, action: {type: string, payload: any}) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case uiTypes.ANALYTICS.TRACK_EXCEPTION:
        writableState.exception = action.payload
        break
      default:
        return writableState
    }
    return writableState
  })
}