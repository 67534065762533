import { ASYNC, actionTypes } from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type ConfigsActionTypes = {
  configs: {
    FETCH_JIRA_LINK: AsyncRequestStatus
    FETCH_FEATURE_SWITCHES: AsyncRequestStatus
  }
}

const types = actionTypes({
  configs: {
    FETCH_JIRA_LINK: ASYNC,
    FETCH_FEATURE_SWITCHES: ASYNC,
  },
}) as ConfigsActionTypes

export default types.configs
