import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  configurator: {
    SET_RECENT_CONFIGURATOR: SYNC,
    FETCH_COUNTRY_ACCOUNTS: ASYNC,
    FETCH_USER_CONFIGURATORS: ASYNC,
    FETCH_GLOBAL_ACCOUNTS: ASYNC,
    FETCH_ACCOUNTS: ASYNC,
    FETCH_SITES: ASYNC,
    FETCH_COUNTRIES: ASYNC,
    FETCH_ITEMS: ASYNC,
    FETCH_DOCUMENTS: ASYNC,
    FETCH_DOCUMENTS_FOR_RELATIONS: ASYNC,
    FETCH_AVAILABLE_GLOBAL_ACCOUNTS: ASYNC,
    FETCH_AVAILABLE_COUNTRIES: ASYNC,
    FETCH_AVAILABLE_ACCOUNTS: ASYNC,
    FETCH_AVAILABLE_SITES: ASYNC,
    FETCH_CONFIGURATOR: ASYNC,
    FETCH_STATISTICS: ASYNC,
    FETCH_CONTENT_ROLES: ASYNC,
    UPDATE_CONFIGURATOR: ASYNC,
    UPDATE_OWNERS: ASYNC,
    SAVE_ITEMS_FOR_CONFIGURATOR: ASYNC,
    SAVE_DOCUMENTS_FOR_CONFIGURATOR: ASYNC,
    CREATE_CONFIGURATOR: ASYNC,
    GET_BY_TAG: ASYNC,
    GET_FILTER_BY: ASYNC,
  },
})

export default types.configurator
