import selectors from "src/store/selectors"
import createAsyncAction from "src/store/utils/createAsyncAction"

/**
 * async action creator function that also binds the application context from state to the payload.
 * For integrating async actions with the redux thunk middleware in a seamless way.
 * @param {any} type redux action type
 * @param {function} asyncFunction asynchronous action to execute
 * @param {function} [metaCreator]
 * @param {function} [mergeContext] function that received the current applicationContext and passes the current context and state
 */
export default function createAsyncActionWithContext(
  type,
  asyncFunction,
  metaCreator,
  mergeContext
) {
  return (payload) => (dispatch, getState) => {
    const currentContext = selectors.getApplicationContext(getState())
    const applicationContext = mergeContext
      ? mergeContext(currentContext, getState, payload)
      : currentContext
    const payloadWithContext = { ...payload, applicationContext }
    return createAsyncAction(type, asyncFunction, metaCreator)(payloadWithContext)(
      dispatch,
      getState
    )
  }
}
