import groupBy from "lodash/groupBy"
import { byDocumentType, byOrder } from "src/components/helpers"

export default function unflattenTree(data, nameOfChildrenProperty = "children") {
  const groupedByParents = groupBy(data, "parentId")
  const roots = data.filter((item) => item.parentId === null).sort(byOrder)

  const getChildren = (item) => groupedByParents[item.opfId] || []
  const addChildren = (item) => ({
    ...item,
    [nameOfChildrenProperty]: getChildren(item).sort(byOrder).sort(byDocumentType).map(addChildren),
  })

  return roots.map(addChildren)
}
