import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  locale: {
    SET_LANGUAGE: SYNC,
    FETCH_LANGUAGES: ASYNC,
    SET_ANTD_LOCALE: SYNC,
  },
})

export default types.locale
