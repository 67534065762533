import { every, isNil, isObject, isString } from "lodash"
import {
  getAllUserTags,
  getCurrentAccountName,
  getCurrentCountryName,
  getCurrentSiteName,
} from "../user/selectors"
import { getCurrentLanguage, getDefaultLanguage, getLanguageTitle } from "../locale/selectors"

import { ACTIONS } from "src/globals"
import { createSelector } from "reselect"
import { getAllConfiguratorTags } from "../configurator/selectors"
import { getInitializationFinished } from "../ui/selectors"
import { getRefinerEnabled } from "../refiner/selectors"
import { groupItemsByType } from "../../services/itemTypes"
import unflattenTree from "../utils/unflattenTree"

const trimSlug = (pathname) => pathname.substring(pathname.indexOf("/processes") + 1)
const replaceOverviewPrefix = (pathname) =>
  pathname
    .substring(pathname.indexOf("/process-overview"))
    .replace("/process-overview", "processes")

export const getSelectedItem = (state) => state.items.selectedItem
export const getPreviouslyFetchedItems = (state) => state.items.fetchedItems
export const itemHasAlreadyBeenFetched = createSelector(
  getPreviouslyFetchedItems,
  (previouslyRequestedItems) => (opfItem) => {
    if (isString(opfItem)) return previouslyRequestedItems.includes(opfItem)
    if (isObject(opfItem))
      return (
        previouslyRequestedItems.includes(opfItem.opfId) ||
        previouslyRequestedItems.includes(opfItem.slug)
      )
    return false
  }
)

const getItemsObj = (state) => state.items.bySlug
export const getItemBySlug = createSelector(
  getItemsObj,
  (itemBySlug) => (slug) => itemBySlug[slug] || null
)
export const getAllItems = createSelector(
  (state) => state.items.allItems,
  (state) => state.items.bySlug,
  (allItems, bySlug) => allItems.map((slug) => bySlug[slug])
)
export const getItemById = createSelector(
  getAllItems,
  (allItems) => (opfId) =>
    allItems.filter((item) => !isNil(item)).find((item) => item.opfId === opfId)
)

export const getItemTagName = createSelector(
  getRefinerEnabled,
  getAllUserTags,
  getAllConfiguratorTags,
  (refinerEnabled, userTags, configuratorTags) => (itemTag) => {
    if (refinerEnabled) {
      const tagInfo = configuratorTags.find((tag) => tag.code === itemTag)
      if (tagInfo) return tagInfo.name
    } else {
      const tagInfo = userTags.find((tag) => tag.code === itemTag)
      if (tagInfo) return tagInfo.name
    }
    const tag = userTags.find((tag) => tag.code === itemTag)
    return tag ? tag.name : null
  }
)

export const getItemsGroupedByType = createSelector(getAllItems, groupItemsByType)
export const getFirstLevelItems = createSelector(getAllItems, (allItems) =>
  allItems.filter((item) => item && item.level === 1)
)
export const getFirstLevelIsLoading = (state) =>
  getInitializationFinished(state) && state.items.firstLevelIsLoading
export const getInitiallyLoadedForContext = (state) => state.items.initialLoadForContext
export const getItemBeingUpdated = (state) => state.items.itemBeingUpdated
export const getAllItemChildren = (state) => (parentItem) => {
  const allItems = getAllItems(state)
  const childSlugs = parentItem.opfChildren
    ? parentItem.opfChildren.map((childItem) => childItem.slug)
    : []
  const children = allItems.filter((item) => childSlugs.includes(item.slug))
  return children
}
export const getAllFavoriteItems = (state) => state.items.allFavorites
export const getItemForCurrentRoute = createSelector(
  (state) => getItemBySlug,
  (itemBySlug) => {
    if (window.location.pathname.includes("/process-overview")) {
      return itemBySlug(replaceOverviewPrefix(pathname))
    }
    return itemBySlug(trimSlug(pathname))
  }
)

export const getItemByCurrentSlugProp = (state, ownProps) => getItemBySlug(state)(ownProps.slug)
export const getFavoriteStateByItemSlug = (state, ownProps) =>
  state.items.allFavorites.includes(ownProps && ownProps.item ? ownProps.item.slug : ownProps.slug)
export const getItemAuditsBySlug = (state) => (slug) => state.items.auditsBySlug[slug] || []
export const getAllItemAudits = (state) => state.items.allAudits
export const getAuditContinuationToken = (state) => state.items.auditContinuationToken
export const getParentItem = (state) => (item) => getItemById(state)(item.parentId)
export const getProcedureEditModeState = (state) => state.items.procedureEditMode
export const getServiceLines = (state) => state.items.serviceLines
export const getIcons = (state) => state.items.icons

const formHelpTextSelectors = [
  getProcedureEditModeState,
  getLanguageTitle,
  getDefaultLanguage,
  getCurrentLanguage,
  getCurrentCountryName,
  getCurrentAccountName,
  getCurrentSiteName,
]

export const getFormItemViewTitle = createSelector(
  ...formHelpTextSelectors,
  (
      procedureEditMode,
      getTitle,
      defaultLanguage,
      currentLanguage,
      currentCountryName,
      currentAccountName,
      currentSiteName
    ) =>
    ({ item, parentItemTitle, intl, addProcedureMode, translateItemMode }) => {
      const type = intl.formatMessage(
        // { id: item.variantId !== null ? "common.variant" : "common.procedure" },
        { id: "common.procedure" },
        { one: (chunk) => chunk, plural: () => "" }
      )
      const procedure = intl.formatMessage(
        { id: "common.procedure" },
        { one: (chunk) => chunk, plural: () => "" }
      )

      const isGlobal = every(
        [currentCountryName, currentAccountName, currentSiteName].map(
          (value) => value === undefined
        ),
        Boolean
      )

      if (addProcedureMode) {
        if (translateItemMode) {
          return {
            id: "form.compare.translate.title",
            values: {
              source: () => type,
              strong: () => <strong>{getTitle(defaultLanguage)}</strong>,
            },
          }
        }
        return {
          id: "form.compare.createProcedure.title",
          values: {
            parentItemTitle: () => parentItemTitle || item.title,
            strong: () => <strong>{getTitle(isGlobal ? defaultLanguage : currentLanguage)}</strong>,
          },
        }
      }
      if (translateItemMode) {
        // if (item.variantId || procedureEditMode === ACTIONS.CREATE_VARIANT)
        //   return {
        //     id: "form.compare.translateVariant.title",
        //     values: {
        //       source: () => procedure,
        //       strong: () => <strong>{getTitle(defaultLanguage)}</strong>,
        //     },
        //   }
        return {
          id: "form.compare.translate.title",
          values: {
            source: () => type,
            strong: () => <strong>{getTitle(defaultLanguage)}</strong>,
          },
        }
      }
      if (procedureEditMode === ACTIONS.TRANSLATE) {
        return {
          id: "form.compare.translate.title",
          values: {
            source: () => type,
            strong: () => <strong>{getTitle(currentLanguage)}</strong>,
          },
        }
      }
      if (procedureEditMode === ACTIONS.EDIT) {
        return item.language !== defaultLanguage
          ? {
              id: "form.compare.editTranslation.title",
              values: {
                source: () => type,
                strong: () => (
                  <strong>
                    {getTitle(
                      item.language !== currentLanguage ? defaultLanguage : currentLanguage
                    )}
                  </strong>
                ),
              },
            }
          : {
              id: "form.compare.edit.title",
              values: {
                source: () => type,
                strong: () => (
                  <strong>
                    {getTitle(item.language !== currentLanguage ? item.language : currentLanguage)}
                  </strong>
                ),
              },
            }
      }
      // return {
      //   id: "form.compare.createVariant.title",
      //   values: {
      //     itemTitle: () => item.title,
      //     strong: () => <strong>{getTitle(currentLanguage)}</strong>,
      //   },
      // }
    }
)

export const getFormItemViewDescription = createSelector(
  ...formHelpTextSelectors,
  (
      procedureEditMode,
      getTitle,
      defaultLanguage,
      currentLanguage,
      currentCountryName,
      currentAccountName,
      currentSiteName
    ) =>
    ({ item, parentItemTitle, intl, addProcedureMode, translateItemMode }) => {
      const itemType = intl.formatMessage(
        // { id: item.variantId !== null ? "common.variant" : "common.procedure" },
        { id: "common.procedure" },
        { one: (chunk) => chunk, plural: () => "" }
      )
      const procedure = intl.formatMessage(
        { id: "common.procedure" },
        { one: (chunk) => chunk, plural: () => "" }
      )
      // const variant =
      //   item.variantId !== null
      //     ? intl.formatMessage(
      //         { id: "common.variant" },
      //         { one: (chunk) => chunk, plural: () => "" }
      //       )
      //     : null
      const scope = intl
        .formatMessage({ id: item.global ? "common.global" : "common.notGloballyApproved" })
        .toLowerCase()

      const target =
        (currentSiteName
          ? `${currentAccountName || currentCountryName}, ${currentSiteName}`
          : currentAccountName || currentCountryName) || intl.formatMessage({ id: "common.global" })

      if (translateItemMode) {
        if (addProcedureMode) {
          if (currentLanguage !== defaultLanguage)
            return intl.formatMessage(
              { id: "form.compare.translateMandatory.description" },
              {
                itemType: () => procedure,
                itemLanguage: () => getTitle(currentLanguage),
                language: () => getTitle(defaultLanguage),
              }
            )
          return intl.formatMessage(
            { id: "form.compare.translate.description" },
            {
              itemType: () => itemType,
              itemLanguage: () => getTitle(currentLanguage),
              language: () => getTitle(defaultLanguage),
            }
          )
        }
        // if (procedureEditMode === ACTIONS.CREATE_VARIANT)
        //   return intl.formatMessage(
        //     { id: "form.compare.translateMandatory.description" },
        //     {
        //       itemType: () =>
        //         intl.formatMessage(
        //           { id: "common.variant" },
        //           { one: (chunk) => chunk, plural: () => "" }
        //         ),
        //       itemLanguage: () => getTitle(currentLanguage),
        //       language: () => getTitle(defaultLanguage),
        //     }
        //   )
        if (procedureEditMode === ACTIONS.EDIT)
          return intl.formatMessage(
            { id: "form.compare.translateMandatory.description" },
            {
              itemType: () => itemType,
              itemLanguage: () => getTitle(currentLanguage),
              language: () => getTitle(defaultLanguage),
            }
          )
        // if (item.variantId)
        //   return intl.formatMessage(
        //     { id: "form.compare.translateMandatory.description" },
        //     {
        //       itemType: () => itemType,
        //       itemLanguage: () => getTitle(currentLanguage),
        //       language: () => getTitle(defaultLanguage),
        //     }
        //   )
        if (!item.global)
          return intl.formatMessage(
            { id: "form.compare.translate.description" },
            {
              itemType: () => procedure,
              itemLanguage: () => getTitle(currentLanguage),
              language: () => getTitle(defaultLanguage),
            }
          )
        return intl.formatMessage(
          { id: "form.compare.translateMandatory.description" },
          {
            itemType: () => procedure,
            itemLanguage: () => getTitle(currentLanguage),
            language: () => getTitle(defaultLanguage),
          }
        )
      }

      if (procedureEditMode === ACTIONS.TRANSLATE) {
        // return !item.variantId ? 
          return intl.formatMessage(
              { id: "form.compare.translate.description" },
              {
                itemType: () => procedure,
                itemLanguage: () => getTitle(item.language),
                language: () => getTitle(currentLanguage),
              }
            )
          // : intl.formatMessage(
          //     { id: "form.compare.translateVariant.description" },
          //     {
          //       itemType: () => itemType,
          //       source: () => scope + " " + procedure,
          //       target: () => target,
          //       itemLanguage: () => getTitle(defaultLanguage),
          //       language: () => getTitle(currentLanguage),
          //     }
          //   )
      }
      if (procedureEditMode === ACTIONS.EDIT) {
        // if (item.variantId) {
        //   return item.language !== defaultLanguage
        //     ? intl.formatMessage(
        //         { id: "form.compare.editVariantLocale.description" },
        //         {
        //           itemType,
        //           variant,
        //           scope,
        //           target,
        //           itemLanguage: getTitle(item.language),
        //           language: getTitle(
        //             item.language !== currentLanguage ? item.language : currentLanguage
        //           ),
        //         }
        //       )
        //     : intl.formatMessage(
        //         { id: "form.compare.editVariant.description" },
        //         {
        //           itemType: () => procedure,
        //           variant: () => variant,
        //           scope: () => scope,
        //           target: () => target,
        //           itemLanguage: () => getTitle(currentLanguage),
        //           language: () =>
        //             getTitle(item.language !== currentLanguage ? item.language : currentLanguage),
        //         }
        //       )
        // } else {
          return item.language === defaultLanguage
            ? null
            : intl.formatMessage(
                { id: "form.compare.translate.description" },
                {
                  itemType: () => procedure,
                  itemLanguage: () => getTitle(defaultLanguage),
                  language: () =>
                    getTitle(item.language !== currentLanguage ? item.language : currentLanguage),
                }
              )
        // }
      }
      // if (procedureEditMode === ACTIONS.CREATE_VARIANT) {
      //   return intl.formatMessage(
      //     { id: "form.compare.createVariant.description" },
      //     {
      //       itemType: () => procedure,
      //       variant: () => variant,
      //       scope: () => scope,
      //       target: () => target,
      //       language: () => getTitle(currentLanguage),
      //     }
      //   )
      // }
    }
)

export const getAllNonLeafItems = (state) => state.items.allNonLeafItems

export const getTreeStructure = createSelector(getAllNonLeafItems, (nonLeafItems) =>
  unflattenTree(nonLeafItems)
)

export const getHasFetchedTreeData = (state) => state.items.hasFetchedTreeData
