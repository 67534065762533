import { api as externalApi } from "../../services/api"
import { createAction } from "redux-actions"
import { createAsyncActionWithContext } from "../utils"
import { getCurrentRefinerSettings } from "./selectors"
import types from "./types"

const api = externalApi()

// composed async action creator function that assigns useprofile to requests
const createAsyncActionWithRefinerContext =
  (type, asyncFunction) => (payload) => (dispatch, getState) => {
    const refinerSettings = getCurrentRefinerSettings(getState())
    const mergeContext = (context) => ({
      ...context,
      mode: "refiner",
      configuratorId: null,
      configuratorType: null,
      user: { ...context.user, ...refinerSettings },
    })
    return createAsyncActionWithContext(type, asyncFunction, null, mergeContext)(payload)(
      dispatch,
      getState
    )
  }

export const fetchTagAGlobalAccounts = createAsyncActionWithRefinerContext(
  types.FETCH_TAG_A,
  api.levels.getGlobalAccounts
)
export const fetchTagACountries = createAsyncActionWithRefinerContext(
  types.FETCH_TAG_A,
  api.levels.getCountries
)
export const fetchTagBAccounts = createAsyncActionWithRefinerContext(
  types.FETCH_TAG_B,
  api.levels.getAccounts
)
export const fetchTagCSites = createAsyncActionWithRefinerContext(
  types.FETCH_TAG_C,
  api.levels.getSites
)
export const toggleRefiner = createAction(types.TOGGLE)
export const setTagA = createAction(types.SET_TAG_A)
export const setTagB = createAction(types.SET_TAG_B)
export const setTagC = createAction(types.SET_TAG_C)
export const setRole = createAction(types.SET_ROLE)
export const setRefiner = createAction(types.SET_REFINER)
export const resetRefiner = createAction(types.RESET)
export const minimizeRefiner = createAction(types.MINIMIZE)
