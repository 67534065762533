import { produce } from "immer"
import types from "./types"

const initialState = Object.freeze({
  enabled: false,
  applied: false,
  minimized: false,
  countries: [],
  globalAccounts: [],
  accounts: [],
  sites: [],
  currentTagA: null,
  currentTagB: null,
  currentTagC: null,
  currentRole: null,
})

const spreadPayloadTypes = [
  types.SET_TAG_A,
  types.SET_TAG_B,
  types.SET_TAG_C,
  types.FETCH_TAG_A.SUCCESS,
  types.FETCH_TAG_B.SUCCESS,
  types.FETCH_TAG_C.SUCCESS,
]

export default function reducer(state = initialState, action) {
  return produce(state, (refiner) => {
    if (spreadPayloadTypes.includes(action.type)) return { ...refiner, ...action.payload }
    switch (action.type) {
      case types.RESET:
        return {
          ...refiner,
          applied: false,
          appliedTagA: null,
          appliedTagB: null,
          appliedTagC: null,
          currentTagA: null,
          currentTagB: null,
          currentTagC: null,
          currentRole: null,
        }

      case types.TOGGLE:
        refiner.enabled = !refiner.enabled
        refiner.applied = false
        break

      case types.MINIMIZE:
        refiner.minimized = action.payload
        break

      case types.SET_REFINER:
        refiner.appliedTagA = refiner.currentTagA
        refiner.appliedTagB = refiner.currentTagB
        refiner.appliedTagC = refiner.currentTagC
        refiner.appliedRole = refiner.currentRole
        refiner.applied = true
        break

      case types.SET_ROLE:
        return {
          ...refiner,
          currentTagA: null,
          currentTagB: null,
          currentTagC: null,
          currentRole: refiner.currentRole ? null : "Global Process Owner",
        }

      default:
        return refiner
    }
    return refiner
  })
}
