import { ASYNC, actionTypes } from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type DraftsActionTypes = {
  drafts: {
    FETCH_MY_DRAFTS: AsyncRequestStatus
  }
}

const types = actionTypes({
  drafts: { FETCH_MY_DRAFTS: ASYNC },
}) as DraftsActionTypes

export default types.drafts
