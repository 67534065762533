import { applyMiddleware } from "redux"
import thunk from "redux-thunk"

const middleware = [
  thunk, // enables async actions in redux
]

// #region thunk?
/**
                                    Thunk Middleware
                      ╔═════════════════════════════════════════╗
                      ║                                         ║
                      ║  1. If the action returns a Function,   ║
    ┌──────────┐      ║          execute the function.          ║  dispatch(action)    ┌───────────┐
    │  Action  │─────▶║                                         ║─────────────────────▶│  Reducer  │
    └──────────┘      ║  2. if the action is an action object;  ║                      └───────────┘
                      ║          execute next(action)           ║
                      ║                                         ║
                      ╚═════════════════════════════════════════╝
                                  nextFunction(next)
                                next = dispatch(action)
  */
// #endregion

export default applyMiddleware(...middleware)
