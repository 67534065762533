import { SUPPORTED_LANGUAGES } from "../../globals"

import { createSelector } from "reselect"
import { getName } from "i18n-iso-countries"
import languages from "langs"
import { split } from "lodash"
import { trimRegionFromLocaleCode } from "../../services/internationalization"

/** get available langauges from the store */
export const getLanguages = (state) => state.locale.languages
export const getCurrentLanguage = (state) => state.locale.currentLanguage
export const getCurrentCachedLanguage = (state) => state.locale.currentLanguageCached
export const getCurrentInterfaceLanguage = (state) => state.intl.locale
export const getDefaultLanguage = (state) => state.intl.defaultLocale
/** get the current react-intl locale (UI internationalization) */
export const getLocale = (state) => state.intl.locale
export const getAntdLocale = (state) => state.locale.antd
/** returns the current language locale without a region prefix (da-dk -> da) */
export const getCurrentLocaleLanguageCode = createSelector(
  getLocale,
  (locale) => split(locale, "-")[0]
)

export const getLanguageName = createSelector(getLanguages, (languages) => (code) => {
  const language = languages.find((language) => language.code === code)
  if (language && language.name) {
    return language.name
  }
  return ""
})

export const getLanguageTitle = createSelector(
  getLanguageName,
  (languageName) => (code) => code ? languageName(code).replace(/\s*\(.*?\)\s*/g, "") : null
)

export const getCurrentLanguageTitle = createSelector(
  getCurrentLanguage,
  getLanguageTitle,
  (language, getTitle) => getTitle(language)
)

export const getCurrentLanguageCode = createSelector(getCurrentLanguage, (language) =>
  trimRegionFromLocaleCode(language)
)

export const createLocalePath = createSelector(
  getCurrentLanguage,
  getDefaultLanguage,
  (currentLanguage, defaultLanguage) => (path) => {
    const ensuredRootLink = path ? (path.startsWith("/") ? path : "/" + path) : ""
    const destination =
      currentLanguage !== defaultLanguage
        ? "/" + currentLanguage + ensuredRootLink
        : ensuredRootLink
    return destination
  }
)

export const getTranslatedCountryName = createSelector(
  getCurrentLanguageCode,
  getDefaultLanguage,
  (languageCode, defaultLanguageCode) => (countryCode) => {
    const suportedLanguagesWithoutRegion = SUPPORTED_LANGUAGES.map(trimRegionFromLocaleCode)
    if (suportedLanguagesWithoutRegion.includes(languageCode)) {
      return getName(countryCode, languageCode)
    } else {
      return getName(countryCode, defaultLanguageCode)
    }
  }
)
