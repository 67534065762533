import { get, has, isEmpty, uniqBy, without } from "lodash"
import { produce } from "immer"

import { INIT_STATUS } from "../../globals"
import types from "./types"
import userTypes from "../user/types"

export const initialState = Object.freeze({
  initStatus: INIT_STATUS.WAITING, // the application initialization status
  showLocal: false,
  displayDraft: false,
  formMode: null,
  currentStep: 0,
  formModalOpen: false,
  localAmendmentModalOpen: false,
  treeDataFetched: false,
  searchText: "",
  searchResults: [],
  searchPages: {},
  messages: [],
  searchFilters: {},
  anonymousMessageClosed: false,
  isSearching: false,
  menuSize: 320,
  recentUserMenuSize: 320,
  compareView: false,
  formModalSubmitCallback: null,
  connectionType: navigator.connection ? navigator.connection.effectiveType : "2g",
})

export default function reducer(state = initialState, action) {
  return produce(state, (ui) => {
    // handle error actions and push an appropriate message to the messages to be displayed by the App)
    if (action.error && ui.initStatus === INIT_STATUS.FINISHED)
      return updateErrorMessages(action, ui)
    switch (action.type) {
      case types.FETCH_SEARCH_FILTERS.SUCCESS:
        {
          const { searchFilters } = action.payload
          ui.searchFilters = {}
          if (isEmpty(searchFilters) === false) {
            searchFilters.forEach((filter) => {
              ui.searchFilters[filter.name] = filter.elements
            })
          }
        }
        break

      case types.SEARCH_ALL.SUCCESS:
        {
          const { opfItems, resultCount } = action.payload
          const { searchParameters, pageNo } = action.meta.request.searchContext
          if (!ui.searchPages[searchParameters]) {
            ui.searchPages = {}
            ui.searchPages[searchParameters] = {}
          }
          ui.searchPages[searchParameters].resultCount = resultCount
          ui.searchPages[searchParameters][pageNo] = opfItems
        }
        break

      case types.TOGGLE_COMPARE_VIEW:
        ui.compareView = !ui.compareView
        break

      case types.TOGGLE_DISPLAY_DRAFT:
        if (action.payload) ui.displayDraft = action.payload
        else ui.displayDraft = !ui.displayDraft
        break

      case types.CONNECTION.CHANGE:
        ui.connectionType = action.payload.effectiveType
        break

      case types.RESTORE_MENU_SIZE:
        ui.menuSize = ui.recentUserMenuSize
        break

      case types.RESIZE_MENU:
        if (action.meta.userResized) {
          ui.recentUserMenuSize = action.payload
        }
        ui.menuSize = action.payload
        break

      case types.SET_SHOW_LOCAL:
        ui.showLocal = action.payload
        break

      case types.SET_APP_LOADING:
        ui.loading = action.payload
        break

      case types.SET_SEARCH_TEXT:
        ui.searchText = action.payload
        break

      case types.SET_CURRENT_PAGE_ITEM:
        ui.currentPageItem = action.payload
        break

      case types.SEARCH.REQUEST:
        ui.isSearching = true
        break

      case types.SEARCH.SUCCESS:
        ui.searchResults = isEmpty(action.payload.opfItems)
          ? initialState.searchResults
          : action.payload.opfItems
        ui.isSearching = false
        break

      case types.SEARCH.FAILURE:
        ui.isSearching = false
        break

      case types.CLEAR_SEARCH_RESULTS:
        ui.searchResults = initialState.searchResults
        break

      case types.CLOSE_MESSAGE: // close a specific error message
        ui.messages = without(ui.messages, action.payload)
        break

      case types.CLEAR_MESSAGES: // clears messages (after they have been displayed)
        ui.messages = initialState.messages
        break

      case userTypes.CHECK_AUTH.SUCCESS:
        ui.anonymousMessageClosed = false
        break

      case types.CLOSE_ANONYMOUS_MESSAGE:
        ui.anonymousMessageClosed = true
        break

      case types.SET_APP_INIT_STATUS:
        if (action.payload.error) ui.initError = action.payload.error
        ui.initStatus = action.payload.status
        break

      case types.TOGGLE_LOCAL_AMENDMENT_MODAL:
        ui.localAmendmentModalOpen = action.payload
        break

      case types.FORM_MODAL.OPEN:
        ui.formModalOpen = true
        ui.formModalMethod = action.payload ? action.payload.method || null : null
        ui.formModalSubmitCallback = action.payload ? action.payload.callback || null : null
        break

      case types.FORM_MODAL.CLOSE:
        ui.formModalOpen = false
        ui.formMode = null
        ui.currentStep = 0
        ui.formModalMethod = null
        ui.formModalSubmitCallback = null
        break

      case types.SET_FORM_MODE: // set the mode for forms current forms on the page (eg. EDIT / ADD ...)
        ui.formMode = action.payload
        break

      case types.SET_CURRENT_INDEX: // set the current index (used for form step handling)
        ui.currentIndex = action.payload
        break

      case types.SET_CURRENT_STEP: // set the current step (used for form step handling)
        ui.currentStep = action.payload
        break

      case types.TOGGLE_PROCEDURE_DRAWER:
        ui.procedureDrawerOpen = action.payload
        break

      case types.TOGGLE_META_DRAWER:
        ui.metaDrawerOpen = action.payload
        break

      case types.TOGGLE_AFFIX_DRAWER:
        ui.affixDrawerOpen = action.payload
        break

      case types.FETCH_TREE_DATA.REQUEST:
      case types.FETCH_TREE_DATA.FAILURE:
        ui.treeDataFetched = false
        break

      case types.FETCH_TREE_DATA.SUCCESS:
        ui.treeDataFetched = true
        break

      default:
        return ui
    }
    return ui
  })
}

function updateErrorMessages(action, ui) {
  let message = has(action, "payload.message") ? action.payload.message : null
  const errorIsHandled = get(action, "meta.handled", false)
  // get the response status if the failed action was async
  const status = get(action, "payload.response.status", null)
  if (errorIsHandled || status === 403) return ui
  const userMessageKeyPath = "payload.response.data.errorResponse.userMessage"
  if (has(action, userMessageKeyPath)) message = get(action, userMessageKeyPath)
  if (message !== null) {
    const newMessage = {
      action,
      timestamp: new Date().valueOf(),
      type: "error",
      key: status,
      message,
      title: action.payload.message,
      status,
    }
    ui.messages.push(newMessage)
    ui.messages = uniqBy(ui.messages, "timestamp")
  }
  return ui
}
