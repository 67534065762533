import { isEmpty, isNil, uniqBy } from "lodash"
import { createSelector } from "reselect"

import { OPF_DOCUMENT_TYPES } from "src/globals"
import { getChildPropertyForItemType } from "./utils"
import { getDocumentSuperType } from "src/components/helpers"
import { getCurrentLanguage } from "src/store/locale/selectors"
import { getCurrentTree } from "src/store/utils/routeUtils"

const { POLICY, STANDARD, POLICIES_STANDARDS_CATEGORY, DOCUMENT_CATEGORY } = OPF_DOCUMENT_TYPES

export const getDocuments = (state) => state.documents
export const getDocumentsAvailableCategoryActions = (state) =>
  state.documents.availableCategoryActions
export const getAllDocumentsBySlug = (state) => state.documents.bySlug
export const getDocumentCategories = (state) => state.document.categories
export const getDocument = (state) => state.documents.opfDocument
export const getAllDocuments = createSelector(getAllDocumentsBySlug, (documentsBySlug) =>
  Object.values(documentsBySlug)
)
export const getAllCategories = createSelector(getAllDocuments, (allDocuments) =>
  allDocuments.filter((opfDocument) => opfDocument.type === POLICIES_STANDARDS_CATEGORY)
)
export const getAllChildDocuments = (state) => (opfDocument) =>
  opfDocument ? getAllDocuments(state).filter((child) => child.parentId === opfDocument.opfId) : []

export const getSelectedDocument = (state) => state.documents.selectedDocument
export const getDocumentEditMode = (state) => state.documents.documentEditMode
export const getDocumentIsSynced = (state) => state.documents.documentSynced
export const getArchivedDocumentBlocks = (state) => state.documents.archivedDocumentBlocks || []
export const getFetchingCategoriesState = (state) => state.documents.fetchingCategories

export const getAllRelationCategories = createSelector(getAllDocuments, (relatedDocuments) =>
  relatedDocuments.filter((opfDocument) => opfDocument.type === POLICIES_STANDARDS_CATEGORY)
)

export const getAllRelatedChildDocuments = createSelector(
  getAllDocuments,
  (allRelatedDocuments) => (opfDocument) =>
    allRelatedDocuments.filter(({ parentId }) => parentId === opfDocument.opfId)
)

export const getRelatedDocumentById = createSelector(
  getAllDocuments,
  (allRelatedDocuments) => (opfId) =>
    allRelatedDocuments.find((opfDocument) => opfDocument.opfId === opfId)
)

export const getRelatedDocumentParentsRecursively = (state) => (opfDocument) => {
  const getParentDocumentsRecursively = (item) => {
    const parent = getRelatedDocumentById(state)(item.parentId)
    if (parent) {
      return [parent, ...[parent].flatMap(getParentDocumentsRecursively)]
    }
    return [item]
  }
  return uniqBy([opfDocument].flatMap(getParentDocumentsRecursively), "opfId")
}

export const getRelatedDocumentChildrenRecursively = (state) => (opfDocument) => {
  const children = getAllRelatedChildDocuments(state)(opfDocument)
  if (!isEmpty(children)) {
    return [{ ...opfDocument }, ...children.flatMap(getRelatedDocumentChildrenRecursively(state))]
  }
  return [opfDocument]
}

export const getAllRelatedChildPoliciesAndStandards = (state) => (opfDocument) => {
  const allChildren = getRelatedDocumentChildrenRecursively(state)(opfDocument) || []
  return allChildren.filter((childDocument) => [POLICY, STANDARD].includes(childDocument.type))
}

export const getDocumentsForCurrentTree = createSelector(
  getAllDocuments,
  getCurrentLanguage,
  (documentsArray, currentLanguage) => {
    const tree = getCurrentTree(currentLanguage)
    return documentsArray.filter((document) => document.tree === tree)
  }
)

export const getCategoriesForCurrentTree = createSelector(
  getAllDocuments,
  getCurrentLanguage,
  (documentsArray, currentLanguage) => {
    const tree = getCurrentTree(currentLanguage)
    const categoriesWithinTree = documentsArray.reduce((accumulated, document) => {
      const superType = getDocumentSuperType(document.type)
      if (document.tree === tree && superType === DOCUMENT_CATEGORY) {
        return { ...accumulated, [document.slug]: document }
      } else {
        return accumulated
      }
    }, {})
    return categoriesWithinTree
  }
)

export const getChaptersObject = (state) => state.documents.chapters
export const getSectionsObject = (state) => state.documents.sections
export const getSubsectionsObject = (state) => state.documents.subsections
export const getAllChapters = (state) => Object.values(getChaptersObject(state))
export const getAllSections = (state) => Object.values(getSectionsObject(state))
export const getAllSubsections = (state) => Object.values(getSubsectionsObject(state))

// generate nested hierarchy based on flat values
const hiearchySelectors = [getAllChapters, getAllSections, getAllSubsections]

const getAllDocumentBlocks = createSelector(
  hiearchySelectors,
  (chapters, sections, subsections) => [...chapters, ...sections, ...subsections]
)

const generateHierarchy = (categories, documents, chapters, sections, subsections) => {
  const childChapters = chapters
    .filter((chapter) => chapter._parentItemId === document.opfId)
    .map((chapter) => {
      const childSections = sections
        .filter((section) => section._parentItemId === chapter.id)
        .map((section) => {
          const childSubsections = subsections.filter(
            (subsection) => subsection._parentItemId === section.id
          )
          return { ...section, subsections: childSubsections }
        })
      return { ...chapter, sections: childSections }
    })
  return { ...document, chapters: childChapters }
}

export const getNewestDocument = (state) => state.documents.newestDocument
export const getDocumentHierarchy = createSelector(hiearchySelectors, generateHierarchy)

export const getDocumentBlockById = (state) => (id) => {
  const allDocumentBlocks = getAllDocumentBlocks(state)
  return allDocumentBlocks.find((block) => block.id === id) || null
}

export const getAllChildBlocksForItem = (state) => (item) => {
  const allDocumentBlocks = getAllDocumentBlocks(state)
  return allDocumentBlocks.filter((block) => block._parentItemId === item.id)
}

const getParentBlocksRecursively = (item) => (accum, block, index, source) => {
  const parent = block.id === item._parentItemId ? block : null
  if (parent && !accum.includes(parent)) {
    accum.push(parent)
    if (parent._parentItemId) {
      const parents = source
        .reduce(getParentBlocksRecursively(parent), accum)
        .filter((parent) => !accum.includes(parent))

      return [...accum, ...parents]
    }
  }
  return accum
}

export const getAllParentBlocksForItem = (state) => (item) => {
  const allDocumentBlocks = getAllDocumentBlocks(state)
  return allDocumentBlocks.reduce(getParentBlocksRecursively(item), []).map((parentBlock) => {
    const childProperty = getChildPropertyForItemType(parentBlock.type)
    if (childProperty) {
      const getChildren = getAllChildBlocksForItem(state)
      const children = getChildren(parentBlock)
      if (children) return { ...parentBlock, [childProperty]: children }
    }
    return parentBlock
  })
}

export const getDocumentBySlug = (state) => (slug) => state.documents.bySlug[slug]

export const getAllItems = createSelector(
  (state) => state.documents.allItems,
  (state) => state.documents.bySlug,
  (allItems, bySlug) => allItems.map((slug) => bySlug[slug])
)

export const getItemById = createSelector(
  getAllItems,
  (allItems) => (opfId) =>
    allItems.filter((item) => !isNil(item)).find((item) => item.opfId === opfId)
)

export const getDocumentsForTree = (state) => (tree) =>
  Object.values(state.documents.bySlug).filter((document) => document.tree === tree)

export const isUploadingRteFile = createSelector(
  getDocuments,
  (documents) => documents.isUploadingRteFile
)
