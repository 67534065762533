import { ROLES } from "../../globals"
import { createSelector } from "reselect"

export const getRefiner = (state) => state.refiner
export const getRefinerEnabled = (state) => state.refiner.enabled
export const getRefinerSettingsApplied = (state) => state.refiner.applied
export const getRefinerMinimized = (state) => state.refiner.minimized

export const userIsAdmin = createSelector(
  getRefiner,
  (refiner) => refiner.currentRole === ROLES.SYS_ADMIN
)
export const getRefinerGlobalAccounts = createSelector(
  getRefiner,
  (refiner) => refiner.globalAccounts
)
export const getRefinerCountries = createSelector(getRefiner, (refiner) => refiner.countries)
export const getRefinerAccounts = createSelector(getRefiner, (refiner) => refiner.accounts)
export const getRefinerSites = createSelector(getRefiner, (refiner) => refiner.sites)
export const getRefinerTagA = createSelector(getRefiner, (refiner) => refiner.currentTagA)
export const getRefinerTagB = createSelector(getRefiner, (refiner) => refiner.currentTagB)
export const getRefinerTagC = createSelector(getRefiner, (refiner) => refiner.currentTagC)

export const getAppliedRefinerSettings = createSelector(getRefiner, (refiner) => {
  return {
    tagA: refiner.appliedTagA,
    tagB: refiner.appliedTagB,
    tagC: refiner.appliedTagC,
    role: refiner.appliedRole,
  }
})
export const getCurrentRefinerSettings = createSelector(getRefiner, (refiner) => {
  return { tagA: refiner.currentTagA, tagB: refiner.currentTagB, tagC: refiner.currentTagC }
})
