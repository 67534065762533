import { DEFAULT_LANGUAGE } from "src/globals"
import languages from "langs"
import { useLocation } from "react-router-dom"

export const getCurrentLanguageInPath = () => {
  const location = useLocation()
  if (!location) return DEFAULT_LANGUAGE

  const pathSnippets = location.pathname.split("/")
  const firstPath = pathSnippets[1]
  // if the current URL already has a language code
  const hasLanguageCode = firstPath ? languages.has("1", firstPath.split("-")[0] || "") : false

  if (hasLanguageCode) return firstPath

  return DEFAULT_LANGUAGE
}
