import { produce } from "immer"
import types from "./types"
import { activateFeature, deactivateFeature } from "src/services/featureFlags"

export type ConfigsState = {
  jiraLink: string
}

type featureSwitch = {
  feature: string
  enabled: boolean
}

export const initialState: ConfigsState = Object.freeze({
  jiraLink: "",
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_JIRA_LINK.SUCCESS:
        writableState.jiraLink = action.payload
        break
      case types.FETCH_FEATURE_SWITCHES.SUCCESS:
        toggleFeatureSwitches(action.payload.features)
        break
      default:
        return writableState
    }
    return writableState
  })
}

const toggleFeatureSwitches = (featureSwitches: featureSwitch[]) =>
  featureSwitches.forEach((fs) =>
    fs.enabled ? activateFeature(fs.feature) : deactivateFeature(fs.feature)
  )
