import { getFormNames, hasSubmitSucceeded, isDirty, isSubmitting } from "redux-form"

import { createSelector } from "reselect"
import { isEmpty } from "lodash"

import { INIT_STATUS } from "../../globals"

export const getMessages = (state) => state.ui.messages
export const getLocalAmendmentModalOpen = (state) => state.ui.localAmendmentModalOpen
export const getApplicationLoadingState = (state) => state.ui.initStatus === INIT_STATUS.LOADING
export const getApplicationWaiting = (state) => state.ui.initStatus === INIT_STATUS.WAITING
export const getMenuSize = (state) => state.ui.menuSize
export const getMenuIsSmall = createSelector(getMenuSize, (size) => size <= 80)
export const getAnonymousMessageClosed = (state) => state.ui.anonymousMessageClosed
export const getAppInitStatus = (state) => state.ui.initStatus
export const getInitializationFinished = (state) => state.ui.initStatus === INIT_STATUS.FINISHED
export const getAppInitFailed = (state) => state.ui.initStatus === INIT_STATUS.FAILED
export const getShowLocal = (state) => state.ui.showLocal
export const getDisplayDraft = (state) => state.ui.displayDraft
export const getFormModalOpenState = (state) => state.ui.formModalOpen
export const getFormModalCallback = (state) => state.ui.formModalSubmitCallback
export const getFormModalMethod = (state) => state.ui.formModalMethod
export const getFormMode = (state) => state.ui.formMode
export const getCurrentIndex = (state) => state.ui.currentIndex
export const getCurrentStep = (state) => state.ui.currentStep
export const getSearchText = (state) => state.ui.searchText
export const getSearchResults = (state) => state.ui.searchResults
export const getIsSearching = (state) => state.ui.isSearching
export const getProcedureDrawerOpenState = (state) => state.ui.procedureDrawerOpen
export const getMetaDrawerOpenState = (state) => state.ui.metaDrawerOpen
export const getAffixDrawerOpenState = (state) => state.ui.affixDrawerOpen
export const getAllActiveForms = (state) => getFormNames()(state)
export const getFormDirtyState = (state) => (form) => isDirty(form)(state)
export const getFormIsSubmitting = (state) => (form) => isSubmitting(form)(state)
export const getFormHasBeenSubmitted = (state) => (form) => hasSubmitSucceeded(form)(state)
export const getConnectionType = (state) => state.ui.connectionType
export const getConnectionIsFast = createSelector(getConnectionType, (type) =>
  ["3g", "4g"].includes(type)
)
export const getInitError = (state) => state.ui.initError
export const getCurrentPageItem = (state) => state.ui.curentPageItem
export const getSearchFilters = (state) => state.ui.searchFilters
export const getSearchQueryPage = (state) => (query, pageNo) =>
  state.ui.searchPages[query] ? state.ui.searchPages[query][pageNo] || null : null
export const getSearchQueryResultCount = (state) => (query) =>
  state.ui.searchPages[query] ? state.ui.searchPages[query].resultCount || 0 : 0

export const getTreeDataFetched = (state) => state.ui.treeDataFetched

export const getContextSwitchIsAllowed = (state) => {
  const activeForms = getFormNames()(state)
  return state.ui.treeDataFetched && !activeForms.length
}

// TODO: rewrite this to be more consistant in updating the values
export const getAnyActiveForms = createSelector(
  [getAllActiveForms, getFormDirtyState, getFormIsSubmitting, getFormHasBeenSubmitted],
  (allActiveForms, isFormDirty, isFormSubmitting, hasFormBeenSubmitted) => {
    if (isEmpty(allActiveForms)) {
      return false
    } else {
      const activeForms = allActiveForms
        // this filtering is to prevent a prompt from appearing when redirecting after a form submission
        .filter((form) => !isFormSubmitting(form)) // exclude forms that are being submitted
        .filter((form) => !hasFormBeenSubmitted(form)) // and exclude already submitted forms

      return activeForms.some(isFormDirty)
    }
  }
)
