import { SYNC, actionTypes } from "../utils"

const types = actionTypes({
  modal: {
    OPEN: SYNC,
    CLOSE: SYNC,
  },
})

export default types.modal
