import { produce } from "immer"
import { Draft } from "src/types/global"
import types from "./types"

export type DraftsSlice = {
  openDrafts: Draft[]
  draftsLoaded: boolean
}

export const initialState: DraftsSlice = Object.freeze({
  openDrafts: [],
  draftsLoaded: false,
})

export default function reducer(state = initialState, action: any) {
  return produce(state, (mutableState) => {
    switch (action.type) {
      case types.FETCH_MY_DRAFTS:
        mutableState.openDrafts = initialState.openDrafts
        mutableState.draftsLoaded = false
        break

      case types.FETCH_MY_DRAFTS.FAILURE:
        mutableState.draftsLoaded = true
        break

      case types.FETCH_MY_DRAFTS.SUCCESS:
        mutableState.openDrafts = action.payload
        mutableState.draftsLoaded = true
        break

      default:
        return mutableState
    }
    return mutableState
  })
}
