import config from "./persist-config"
import configurator from "./configurator/reducer"
import documents from "./documents/reducer"
import { filterActions } from "redux-ignore"
import { reducer as form } from "redux-form"
import { intlReducer as intl } from "react-intl-redux"
import items from "./items/reducer"
import locale from "./locale/reducer"
import configs from "./configs/reducer"
import drafts from "./drafts/reducer"
import { persistCombineReducers } from "redux-persist"
import refiner from "./refiner/reducer"
import ui from "./ui/reducer"
import user from "./user/reducer"
import modal from "./modal/reducer"
import analytics from "./analytics/reducer"

// filtering the actions by type namespace allows the store to skip certain reducers
const createRootReducer = () =>
  persistCombineReducers(config, {
    user: filterActions(user, (action) => /user/.test(action.type)),
    refiner: filterActions(refiner, (action) => /refiner/.test(action.type)),
    locale: filterActions(locale, (action) => /locale/.test(action.type)),
    form: filterActions(form, (action) => /@@redux-form/.test(action.type)),
    configurator,
    items,
    documents,
    configs,
    drafts,
    ui,
    intl,
    modal,
    analytics,
  })

export default createRootReducer
