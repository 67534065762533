import { head, isEmpty, get } from "lodash"

import { produce } from "immer"
import types from "./types"

const initialState = Object.freeze({
  authenticated: false,
  roles: [],
  levelATags: [],
  levelBTags: [],
  levelCTags: [],
})

export default function reducer(state = initialState, action) {
  return produce(state, (user) => {
    switch (action.type) {
      case types.SET_ROLE:
        {
          const newRole = user.roles.find((role) => role.name === action.payload)
          user.currentRole = newRole
          user.levelATags = newRole.levelATags
          user.levelBTags = newRole.levelBTags
          user.levelCTags = newRole.levelCTags
          user.currentTagA = isEmpty(newRole.levelATags) ? null : head(newRole.levelATags).code
          user.currentTagB = isEmpty(newRole.levelBTags) ? null : head(newRole.levelBTags).code
          user.currentTagC = isEmpty(newRole.levelCTags) ? null : head(newRole.levelCTags).code
        }
        break

      case types.FETCH_CONFIGURATOR.SUCCESS: // sets the configurator which triggers a reload of items with new context
        user.configurator =
          action.payload.configurator !== null
            ? { ...action.payload.configurator, timestamp: Date.now() }
            : null
        break

      case types.CHECK_AUTH.SUCCESS:
        user.authenticated = true
        break

      case types.CHECK_AUTH.FAILURE: // if checkAuthentication calls fails (HTTP 403) the user is no longer authenticated
        user.authenticated = false
        break

      case types.SET_COUNTRY: // set current tag A and clear subsequent tags B and configurator
        user.currentTagA = action.payload
        user.currentTagB = isEmpty(user.currentRole.levelBTags)
          ? null
          : get(
              user.currentRole.levelBTags.find((tag) => tag.code === user.currentTagB),
              "code",
              null
            )
        user.currentTagC = isEmpty(user.currentRole.levelCTags)
          ? null
          : get(
              user.currentRole.levelCTags.find((tag) => tag.code === user.currentTagC),
              "code",
              null
            )
        break

      case types.SET_ACCOUNT: // set current tag B and clear subsequent tag C and configurator
        user.currentTagB = action.payload
        user.currentTagC = isEmpty(user.currentRole.levelCTags)
          ? null
          : get(
              user.currentRole.levelCTags.find((tag) => tag.code === user.currentTagC),
              "code",
              null
            )
        break

      case types.SET_SITE: // set current tag C and clear configurator
        user.currentTagC = action.payload
        break

      case types.FETCH_PROFILE.SUCCESS: // update the current user state with values from api
        return validateAndUpdateUserProfile(action.payload.userProfile, user)
      default:
        return user
    }
    return user
  })
}

function validateAndUpdateUserProfile(profile, user) {
  const updatedUser = {
    ...user,
    ...profile,
    authenticated: true,
  }

  const { roles } = profile
  const defaultRole = isEmpty(roles) ? null : head(roles)
  const currentRoleFromProfile = roles.find((role) => role.name === user?.currentRole?.name)

  if (user.upn !== profile.upn) setDefaultRole(updatedUser, defaultRole) // Different user
  else if (currentRoleFromProfile === undefined)
    setDefaultRole(updatedUser, defaultRole) // Cannot find cached current role in new roles
  else {
    // if the stored role exists; take it from the user profile response to ensure rights are up to date
    updatedUser.currentRole = currentRoleFromProfile
    updatedUser.levelATags = currentRoleFromProfile.levelATags
    updatedUser.levelBTags = currentRoleFromProfile.levelBTags
    updatedUser.levelCTags = currentRoleFromProfile.levelCTags
    updatedUser.currentTagA = isEmpty(updatedUser.levelATags)
      ? null
      : head(updatedUser.levelATags).code
    updatedUser.currentTagB = isEmpty(updatedUser.levelBTags)
      ? null
      : head(updatedUser.levelBTags).code
    updatedUser.currentTagC = isEmpty(updatedUser.levelCTags)
      ? null
      : head(updatedUser.levelCTags).code
  }
  return updatedUser
}

function setDefaultRole(user, defaultRole) {
  const { levelATags, levelBTags, levelCTags } = defaultRole
  user.currentRole = defaultRole
  user.levelATags = levelATags
  user.levelBTags = levelBTags
  user.levelCTags = levelCTags
  user.currentTagA = isEmpty(levelATags) ? null : head(levelATags).code
  user.currentTagB = isEmpty(levelBTags) ? null : head(levelBTags).code
  user.currentTagC = isEmpty(levelCTags) ? null : head(levelCTags).code
}
