import { bindActionCreators } from "redux"

/**
 * automatically bind action creator functions with dispatch for passing as props to react component
 * @example
 * ```js
    mapDispatchToProps(bindActions({ propName: actionCreator }))
  ```
 * @param {*} actions
 */
export default function bindActions(actions) {
  return (dispatch) => ({ ...bindActionCreators(actions, dispatch) })
}
