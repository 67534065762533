import countries, { getName } from "i18n-iso-countries"
import { getCurrentLanguageCode, getDefaultLanguage } from "../locale/selectors"
import { last, uniq, uniqBy } from "lodash"

import { OPF_DOCUMENT_TYPES } from "../../globals"
import { createSelector } from "reselect"

const { POLICIES_STANDARDS_CATEGORY, POLICY, STANDARD } = OPF_DOCUMENT_TYPES
const getParentSlug = (pathname) =>
  pathname
    .split("/")
    .filter((path, index, array) => path !== last(array))
    .join("/")

export const getGlobalItems = (state) => state.configurator.globalItems
export const getMostRecentConfigurator = (state) => state.configurator.mostRecent
export const getStatisticsLoading = (state) => state.configurator.statisticsLoading
export const getConfigurators = (state) => Object.values(state.configurator.configurators)
export const getConfiguratorById = (state) => (id) => state.configurator.configurators[id] || null

// hierarchy setup
export const getCountries = (state) => state.configurator.countries || []
export const getAccounts = (state) => state.configurator.accounts || []
export const getSites = (state) => state.configurator.sites || []
export const getCountryAccounts = (state) => state.configurator.countryAccounts || []
export const getGlobalAccounts = (state) => state.configurator.globalAccounts || []

// available claims not yet created as configurators
export const getAvailableCountries = (state) => state.configurator.availableCountries || []
export const getAvailableAccounts = (state) => state.configurator.availableAccounts || []
export const getAvailableGlobalAccounts = (state) =>
  state.configurator.availableGlobalAccounts || []
export const getAvailableSites = (state) => state.configurator.availableSites || []

export const getCurrentTotalProcedures = (state) => state.configurator.totalProcedures
export const getCurrentTotalPolicies = (state) => state.configurator.totalPolicies

export const getAllGlobalItems = (state) =>
  state.configurator.allGlobalItems.map((slug) => state.configurator.globalItemBySlug[slug])
export const isItemFullyLoaded = (state) => (opfId) =>
  state.configurator.fullyLoadedItems.includes(opfId)

export const getAllConfiguratorTags = createSelector(
  getCountries,
  getAccounts,
  getGlobalAccounts,
  getSites,
  (countries, accounts, globalAccounts, sites) => [
    ...countries,
    ...globalAccounts,
    ...accounts,
    ...sites,
  ]
)

export const getFirstLevelGlobalItems = (state) =>
  getAllGlobalItems(state).filter((item) => item && item.level === 1)
export const getGlobalItemBySlug = (state) => (slug) =>
  state.configurator.globalItemBySlug[slug] || null
export const getGlobalItemById = (state) => (opfId) =>
  getAllGlobalItems(state).find((item) => item.opfId === opfId)
export const getParentItem = (state) => (item) =>
  getGlobalItemBySlug(state)(getParentSlug(item.slug))

export const getParentItems = (state) => (item) => {
  const getOpfItemParentsRecursively = (opfItem) => {
    const parent = getGlobalItemById(state)(opfItem.parentId)
    if (parent) {
      return [parent, ...[parent].flatMap(getOpfItemParentsRecursively)]
    }
    return [opfItem]
  }
  return uniqBy([item].flatMap(getOpfItemParentsRecursively), "opfId")
}

export const getDocumentBySlug = (state) => (slug) =>
  state.configurator.allDocuments.find((opfDocument) => opfDocument.slug === slug)
export const getParentDocument = (state) => (opfDocument) =>
  getDocumentBySlug(state)(getParentSlug(opfDocument.slug))
export const getParentDocuments = (state) => (item) => {
  const getOpfDocumentParentsRecursively = (opfDocument) => {
    const parent = getParentDocument(state)(opfDocument)
    if (parent) {
      return [parent, ...[parent].flatMap(getOpfDocumentParentsRecursively)]
    }
    return [opfDocument]
  }
  return uniqBy([item].flatMap(getOpfDocumentParentsRecursively), "opfId")
}

export const getItemFullDepthIsLoaded = (state) => state.configurator.fullDepthLoaded

export const getItemsIdsForConfigurator = createSelector(
  getAllGlobalItems,
  (allItems) => (configuratorId) =>
    uniq(
      allItems
        .filter((item) => item.configurators && item.configurators.includes(configuratorId))
        .map((item) => item.opfId)
    )
)

const getOpfItemChildrenRecursively = (state) => (opfItem) =>
  [opfItem].reduce((accum, item) => {
    accum.push(item)
    if (item.opfChildren && item.opfChildren.length) {
      accum = accum.concat(
        item.opfChildren.flatMap((child) => getOpfItemChildrenRecursively(state)(child))
      )
      return accum
    }
    const fullItemData = getGlobalItemById(state)(opfItem.opfId)
    if (fullItemData) {
      if (fullItemData.opfChildren && fullItemData.opfChildren.length) {
        accum.concat(
          fullItemData.opfChildren.flatMap((child) => getOpfItemChildrenRecursively(state)(child))
        )
      }
    }
    return accum
  }, [])

export const getFlatItemHierachy = (state) => (item) => {
  if (!item) return []
  return getOpfItemChildrenRecursively(state)(item)
}

const getOpfDocumentChildrenRecursively = (state) => (opfDocument) =>
  [opfDocument].reduce((accum, opfDocument) => {
    accum.push(opfDocument)
    if (opfDocument.opfDocumentChildren && opfDocument.opfDocumentChildren.length) {
      accum = accum.concat(
        opfDocument.opfDocumentChildren.flatMap((child) =>
          getOpfDocumentChildrenRecursively(state)(child)
        )
      )
      return accum
    }
    const fullDocument = getDocumentById(state)(opfDocument.opfId)
    if (fullDocument) {
      if (fullDocument.opfDocumentChildren && fullDocument.opfDocumentChildren.lgnth) {
        accum.concat(
          fullDocument.opfChildren.flatMap((child) =>
            getOpfDocumentChildrenRecursively(state)(child)
          )
        )
      }
    }
    return accum
  }, [])

export const getFlatDocumentHierarchy = (state) => (opfDocument) => {
  if (!opfDocument) return []
  return getOpfDocumentChildrenRecursively(state)(opfDocument)
}

export const getHiearchyIdsForConfigurator = createSelector(
  getFlatItemHierachy,
  (flattenItem) => (item, configuratorId) => {
    const hiearchy = flattenItem(item)
    return hiearchy.filter((item) => item.configurators.includes(configuratorId))
  }
)

export const getConfiguratorTitle = createSelector(
  getCurrentLanguageCode,
  getDefaultLanguage,
  getAllConfiguratorTags,
  (currentLanguage, defaultLanguage, configuratorTags) => (configurator) => {
    if (!configurator) return null
    if (configurator.tagC) {
      const tagCInfo = configuratorTags.find((tag) => tag.code === configurator.tagC)
      return tagCInfo ? tagCInfo.name : configurator.tagC
    }
    if (configurator.tagB) {
      const tagBInfo = configuratorTags.find((tag) => tag.code === configurator.tagB)
      return tagBInfo ? tagBInfo.name : configurator.tagC
    }
    if (countries.isValid(configurator.tagA)) {
      const translatedCountryName =
        getName(configurator.tagA, currentLanguage) || getName(configurator.tagA, defaultLanguage)
      if (translatedCountryName) return translatedCountryName
    }
    const tagAInfo = configuratorTags.find((tag) => tag.code === configurator.tagA)
    return tagAInfo ? tagAInfo.name : configurator.tagA
  }
)

// export const getDocumentCategories = state => state.
export const getAllDocuments = (state) => state.configurator.allDocuments
export const getAllPoliciesAndStandards = createSelector(getAllDocuments, (allDocuments) =>
  allDocuments.filter((opfDocument) => [POLICY, STANDARD].includes(opfDocument.type))
)
export const getDocumentCategories = createSelector(getAllDocuments, (documents) =>
  documents
    ? documents.filter((opfDocument) => opfDocument.type === POLICIES_STANDARDS_CATEGORY) || []
    : []
)
export const getDocumentByOpfId = (state) => (opfId) =>
  state.configurator.allDocuments.find((document) => document.opfId === opfId) || null
export const getCategoryByOpfId = createSelector(getDocumentCategories, (categories) => (opfId) =>
  categories.find((category) => category.opfId === opfId)
)

export const getDocumentById = (state) => (id) =>
  state.configurator.allDocuments.find((opfDocument) => opfDocument.id === id) || null
export const getCategoryById = createSelector(getDocumentCategories, (categories) => (id) =>
  categories.find((category) => category.id === id)
)
