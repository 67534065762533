import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  user: {
    FETCH_PROFILE: ASYNC,
    FETCH_CONFIGURATOR: ASYNC,
    SET_SITE: SYNC,
    SET_ACCOUNT: SYNC,
    SET_COUNTRY: SYNC,
    SET_ROLE: SYNC,
    LOG_OUT: SYNC,
    CHECK_AUTH: ASYNC,
  },
})

export default types.user
