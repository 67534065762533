import { createAsyncAction } from "../utils"

import { api as externalApi } from "../../services/api"
import types from "./types"

const api = externalApi()

export const fetchJiraLink = createAsyncAction(types.FETCH_JIRA_LINK, api.config.getJiraLink)
export const fetchFeatureSwitches = createAsyncAction(
  types.FETCH_FEATURE_SWITCHES,
  api.config.getFeatureSwitches
)
