import { createAsyncAction, createAsyncActionWithContext } from "../utils"

import { api as externalApi } from "../../services/api"
import { createAction } from "redux-actions"
import { isString } from "lodash"
import selectors from "../selectors"
import types from "./types"

const { getItemBySlug } = selectors.itemSelectors

const api = externalApi()

export const fetchItems = createAsyncActionWithContext(
  types.FETCH_ITEMS,
  api.items.getItems,
  (meta) => ({ showProgressIndicator: true, triggeredBy: meta.triggeredBy })
)

export const fetchChangelog = createAsyncActionWithContext(
  types.FETCH_CHANGELOG,
  api.items.fetchDocumentChangelog
)

export const fetchItemBySlug = createAsyncActionWithContext(
  types.FETCH_ITEM_BY_SLUG,
  api.items.getItemBySlug,
  (meta) => ({ showProgressIndicator: true, triggeredBy: meta.triggeredBy })
)

const selectItemAction = createAction(types.SELECT_ITEM)
export const selectItem = (payload) => (dispatch, getState) => {
  if (isString(payload)) {
    // if payload is a string, try to find item by slug
    const item = getItemBySlug(getState())(payload.opfItem)
    if (item !== undefined) return dispatch(selectItemAction(item))
  }
  dispatch(selectItemAction(payload))
}
export const getLanguagesForItem = createAsyncActionWithContext(
  types.FETCH_ITEM_LANGUAGES,
  api.items.getLanguagesForItem
)
export const getVersionForItemLanguage = createAsyncActionWithContext(
  types.FETCH_ITEM_VERSIONS,
  api.items.getVersionForItemLanguage
)
export const clearSelectedItem = createAction(types.CLEAR_SELECTED_ITEM)
export const createItem = createAsyncActionWithContext(types.CREATE_ITEM, api.items.createItem)
export const createItemDraft = createAsyncActionWithContext(
  types.CREATE_ITEM_DRAFT,
  api.items.createItemDraft
)
export const createItemVariantDraft = createAsyncActionWithContext(
  types.CREATE_ITEM_VARIANT_DRAFT,
  api.items.createItemVariantDraft
)
export const createItemTranslation = createAsyncActionWithContext(
  types.CREATE_ITEM_TRANSLATION,
  api.items.createItemTranslation
)
export const createItemFromWord = createAsyncActionWithContext(
  types.CREATE_ITEM_FROM_WORD,
  api.items.createItemFromWord
)
export const createItemVariant = createAsyncActionWithContext(
  types.CREATE_ITEM_VARIANT,
  api.items.createItemVariant
)
export const updateItem = createAsyncActionWithContext(types.UPDATE_ITEM, api.items.updateItem)
export const getLatestDraft = createAsyncActionWithContext(
  types.GET_LATEST_DRAFT,
  api.items.getLatestDraft
)
export const saveDraft = createAsyncActionWithContext(types.SAVE_DRAFT, api.items.saveDraft)
export const prePublishMinorVersion = createAsyncActionWithContext(
  types.PRE_PUBLISH_MINOR_VERSION,
  api.items.prePublishMinorVersion
)
export const prePublishMajorVersion = createAsyncActionWithContext(
  types.PRE_PUBLISH_MAJOR_VERSION,
  api.items.prePublishMajorVersion
)
export const publishItem = createAsyncActionWithContext(types.PUBLISH_ITEM, api.items.publishItem)
export const translateItem = createAsyncActionWithContext(
  types.TRANSLATE_ITEM,
  api.items.translateItem
)
export const lockItem = createAsyncActionWithContext(types.LOCK_ITEM, api.items.lockItem)
export const unlockItem = createAsyncActionWithContext(types.UNLOCK_ITEM, api.items.unlockItem)
export const archiveItem = createAsyncActionWithContext(types.ARCHIVE_ITEM, api.items.archiveItem)
export const removeItemFromConfigurator = createAsyncActionWithContext(
  types.REMOVE_ITEM_FROM_CONFIGURATOR,
  api.items.removeItemFromConfigurator
)
export const reorderItems = createAsyncActionWithContext(types.REORDER_ITEMS, api.items.reorder)
export const getAllNonLeafItems = createAsyncActionWithContext(
  types.FETCH_ALL_NON_LEAF_ITEMS,
  api.items.getAllNonLeafItems
)
export const moveItem = createAsyncActionWithContext(types.MOVE_ITEM, api.items.moveItem)
export const deleteFile = createAsyncActionWithContext(types.DELETE_FILE, api.items.deleteFile)
export const uploadFile = createAsyncActionWithContext(types.UPLOAD_FILE, api.items.uploadFile)
export const getFavorites = createAsyncActionWithContext(
  types.FAVORITES.FETCH,
  api.favorites.getUserFavorites
)
export const addToFavorites = createAsyncActionWithContext(
  types.FAVORITES.ADD,
  api.favorites.addToFavorites
)
export const removeFromFavorites = createAsyncActionWithContext(
  types.FAVORITES.REMOVE,
  api.favorites.removeFromFavorites
)
export const updateFavorites = createAsyncActionWithContext(
  types.FAVORITES.UPDATE,
  api.favorites.updateFavorites
)
export const getItemAudits = createAsyncActionWithContext(types.AUDITS.FETCH, api.audits.getAudits)
export const getAllItemAudits = createAsyncActionWithContext(
  types.AUDITS.FETCH_ALL,
  api.audits.getAllAudits
)
export const fetchServiceLines = createAsyncActionWithContext(
  types.SERVICELINES.FETCH,
  api.serviceLines.getServiceLines
)
export const clearAllItemAudits = createAction(types.AUDITS.CLEAR)
export const setAuditContinuationToken = createAction(types.AUDITS.SET_TOKEN)
export const setCurrentPageItem = createAction(types.SET_CURRENT_PAGE_ITEM)
export const setProcedureEditMode = createAction(types.TOGGLE_PROCEDURE_EDIT_MODE)
export const fetchIcons = createAsyncAction(types.FETCH_ICONS, api.items.getIcons)
