import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  items: {
    FETCH_ICONS: ASYNC,
    FETCH_ITEMS: ASYNC,
    FETCH_ITEM_BY_SLUG: ASYNC,
    FETCH_ALL_NON_LEAF_ITEMS: ASYNC,
    FETCH_ITEM_LANGUAGES: ASYNC,
    FETCH_ITEM_VERSIONS: ASYNC,
    CLEAR_FETCHED_ITEMS: SYNC,
    SELECT_ITEM: SYNC,
    CLEAR_SELECTED_ITEM: SYNC,
    CREATE_ITEM: ASYNC,
    CREATE_ITEM_DRAFT: ASYNC,
    CREATE_ITEM_TRANSLATION: ASYNC,
    CREATE_ITEM_FROM_WORD: ASYNC,
    CREATE_ITEM_VARIANT: ASYNC,
    CREATE_ITEM_VARIANT_DRAFT: ASYNC,
    UPDATE_ITEM: ASYNC,
    TRANSLATE_ITEM: ASYNC,
    LOCK_ITEM: ASYNC,
    UNLOCK_ITEM: ASYNC,
    REORDER_ITEMS: ASYNC,
    MOVE_ITEM: ASYNC,
    DELETE_FILE: ASYNC,
    UPLOAD_FILE: ASYNC,
    SAVE_DRAFT: ASYNC,
    GET_LATEST_DRAFT: ASYNC,
    PRE_PUBLISH_MINOR_VERSION: ASYNC,
    PRE_PUBLISH_MAJOR_VERSION: ASYNC,
    PUBLISH_ITEM: ASYNC,
    ARCHIVE_ITEM: ASYNC,
    REMOVE_ITEM_FROM_CONFIGURATOR: ASYNC,
    UNARCHIVE_ITEM: ASYNC,
    SET_CURRENT_PAGE_ITEM: SYNC,
    TOGGLE_PROCEDURE_EDIT_MODE: SYNC,
    FAVORITES: {
      FETCH: ASYNC,
      ADD: ASYNC,
      UPDATE: ASYNC,
      REMOVE: ASYNC,
    },
    AUDITS: {
      CLEAR: SYNC,
      FETCH: ASYNC,
      FETCH_ALL: ASYNC,
      SET_TOKEN: SYNC,
    },
    SERVICELINES: {
      FETCH: ASYNC,
    },
    FETCH_CHANGELOG: ASYNC,
  },
})

export default types.items
