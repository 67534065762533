import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import createCompressor from "redux-persist-transform-compress"
import { createWhitelistFilter } from "redux-persist-transform-filter"
import localForage from "localforage"
import crypto from "crypto"
import createEncryptor from "redux-persist-transform-encrypt"

// state to persist
const whitelists = [
  // 👤 user properties to cache
  createWhitelistFilter("user", [
    "upn",
    "defaultRole",
    "currentRole",
    "currentTagA",
    "currentTagB",
    "currentTagC",
    "configurator",
  ]),
  createWhitelistFilter("ui", ["menuSize"]),
  createWhitelistFilter("locale", ["currentLanguageCached"]),
  createWhitelistFilter("documents", ["relatedDocuments"]),
]

let cacheCleared = false

const sha256 = (data) => {
  const hash = crypto.createHash("sha256").update(data).digest("hex")
  return hash
}
// 🗝️ encrypts data; to best prevent sniffing of user data by browser plugins, etc.
const encryptor = createEncryptor({
  secretKey: sha256(process.env.VERSION + "mySaltString"), // Should be dynamic and not hardcoded
  onError: function (error) {
    console.error("Decryption failed:", error)

    if (cacheCleared === false && window.persistor) {
      window.persistor.purge()
      cacheCleared = true
    }
  },
})

const persistConfig = {
  key: "root",
  storage: localForage, // use localforage for handling saving data to the client most efficently with fallbacks
  stateReconciler: autoMergeLevel2, // merge cached state tree two levels down
  version: process.env.VERSION, // cache gets invalidated if package.json version is changed for the webapp
  whitelist: ["user", "locale", "ui", "documents"], // whitelist of state subtrees to cache
  transforms: [...whitelists, encryptor, createCompressor()],
}

export default persistConfig
