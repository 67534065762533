import {
  ACTIONS,
  DEFAULT_LANGUAGE,
  OPF_CONFIGURATION_TYPES,
  OPF_ITEM_TYPES,
  ROLES,
} from "../../globals"
import { camelCase, pick } from "lodash"

import { createSelector } from "reselect"
import { getCurrentLanguage, getLanguages } from "../locale/selectors"
import { getInitializationFinished } from "../ui/selectors"

const {
  EDIT,
  REFINER,
  ADD_STEP,
  ARCHIVE,
  REMOVE_FROM_CONFIGURATOR,
  TRANSLATE,
  LOCK,
  UNLOCK,
  EDIT_STEP,
  DELETE_STEP,
  ADD_CHAPTER,
  ADD_CHAPTER_VARIANT,
  PRINT,
  EDIT_LOCAL_AMENDMENT,
} = ACTIONS
const { CONFIGURATOR } = OPF_ITEM_TYPES
const { GLOBAL_ACCOUNT, GLOBAL_ACCOUNT_COUNTRY, ACCOUNT, COUNTRY, SITE } = OPF_CONFIGURATION_TYPES

export const getUserIsAuthenticated = (state) => state.user.authenticated
export const getUser = (state) => state.user
export const getCurrentConfigurator = (state) => state.user.configurator
export const getCurrentCountry = (state) => state.user.currentTagA
export const getCurrentAccount = (state) => state.user.currentTagB
export const getCurrentSite = (state) => state.user.currentTagC
export const getConfiguratorType = (state) =>
  state.user.currentRole && state.user.currentRole.configuratorType
    ? state.user.currentRole.configuratorType
    : state.user.configuratorType
export const getConfiguratorCountry = (state) =>
  state.user.configurator ? state.user.configurator.configuratorCountry : null

export const getCurrentRole = createSelector(getUser, (user) => user.currentRole)
export const userIsAdmin = createSelector(getCurrentRole, (role) => role.name === ROLES.SYS_ADMIN)
export const getUserManagesLocalItems = createSelector(
  getUser,
  (user) =>
    [ROLES.GLOBAL_PROCESS_OWNER, ROLES.GLOBAL_ACCOUNT_PROCESS_OWNER].includes(
      user.currentRole.name
    ) === false
)
export const getUserAccounts = createSelector(getUser, (user) => user.levelBTags)
export const getUserCountries = createSelector(getUser, (user) => user.levelATags)
export const getUserSites = createSelector(getUser, (user) => user.levelCTags)
export const getRoles = createSelector(getUser, (user) => user.roles)
export const getUserLanguageName = createSelector(
  getCurrentLanguage,
  getLanguages,
  (currentLanguageCode, languages) => {
    if (!languages || currentLanguageCode === DEFAULT_LANGUAGE) return
    const currentLanguage = languages.find(({ code }) => code === currentLanguageCode)
    return currentLanguage.name
  }
)

export const getCurrentCountryName = createSelector(
  getCurrentCountry,
  getUserCountries,
  (countryCode, countries) => {
    const country = countries.find((country) => country.code === countryCode)
    if (country) return country.name
  }
)
export const getCurrentAccountName = createSelector(
  getCurrentAccount,
  getUserAccounts,
  (accountCode, accounts) => {
    const account = accounts.find((account) => account.code === accountCode)
    if (account) return account.name
  }
)
export const getCurrentSiteName = createSelector(
  getCurrentSite,
  getUserSites,
  (siteCode, sites) => {
    const site = sites.find((site) => site.code === siteCode)
    if (site) return site.name
  }
)

export const getUserAccessToConfigurator = createSelector(getUser, (user) => {
  if (user.authenticated === false) return false
  if (user.currentRole && user.currentRole.access) {
    const configuratorAccess = user.currentRole.access.find(
      (access) => access.type === CONFIGURATOR
    )
    if (configuratorAccess === undefined) return false
    return configuratorAccess
  }
  return false
})

export const getUserAccessToDrafts = createSelector(getUser, (user) => {
  if (user.authenticated === false) return false

  if (user.currentRole && user.currentRole.access) {
    if (user.currentRole.access.length === 0) {
      return false
    }

    return true
  }

  return false
})

export const getUserAccessToRefiner = createSelector(
  getUser,
  getInitializationFinished,
  (user, initialized) => {
    if (!initialized) return false
    if (user.authenticated === false) return false
    if (user.currentRole && user.currentRole.access) {
      const refinerAccess = user.currentRole.access.find((access) => access.type === REFINER)
      return !!refinerAccess
    }
    return false
  }
)

export const getConfiguratorRight = createSelector(
  getUserAccessToConfigurator,
  getInitializationFinished,
  (configuratorAccess, initialized) => (right) => {
    if (!initialized) return false
    if (configuratorAccess && configuratorAccess.actions) {
      return configuratorAccess.actions.includes(right)
    }
    return false
  }
)

export const getAccessToConfiguratorTree = createSelector(
  getUserAccessToConfigurator,
  getInitializationFinished,
  (configuratorAccess, initialized) => (tree) => {
    if (!initialized) return false
    if (configuratorAccess?.trees) {
      return configuratorAccess.trees.includes(tree)
    }
    return false
  }
)

export const getUserProfile = createSelector(
  getUser,
  getConfiguratorType,
  (user, configuratorType) => {
    const profileProps = [
      "name",
      "upn",
      "email",
      "roles",
      "levelATags",
      "levelBTags",
      "levelCTags"
    ]
    const userProfile = { ...pick(user, profileProps), configuratorType }
    return userProfile
  }
)

// TODO: ⚠️  a lot of the logic here should be moved to the backend in the future
const {
  // EDIT_VARIANT,
  // ARCHIVE_VARIANT,
  // TRANSLATE_VARIANT,
  // LOCK_VARIANT,
  // UNLOCK_VARIANT,
  // ADD_VARIANT_STEP,
  // EDIT_VARIANT_STEP,
  // DELETE_VARIANT_STEP,
  // // CREATE_VARIANT,
  UPDATE_ORDER,
  ADD,
  MOVE,
} = ACTIONS

export const getUserItemRights = (state) => (item) => {
  if (state.user.authenticated === false || !item) return false
  const availableActions = item.availableActions || []
  // const itemIsVariant = item.variantId !== null
  const currentLanguage = getCurrentLanguage(state)
  // if (itemIsVariant) {
  //   return {
  //     add: availableActions.includes(ADD),
  //     edit: availableActions.includes(EDIT_VARIANT),
  //     archive: availableActions.includes(ARCHIVE_VARIANT),
  //     move: availableActions.includes(MOVE),
  //     removeFromConfigurator: availableActions.includes(REMOVE_FROM_CONFIGURATOR),
  //     lock: availableActions.includes(LOCK_VARIANT),
  //     unlock: availableActions.includes(UNLOCK_VARIANT),
  //     translate:
  //       availableActions.includes(TRANSLATE_VARIANT) &&
  //       (!item.translated ||
  //         item.language === DEFAULT_LANGUAGE ||
  //         item.langauge !== currentLanguage),
  //     addStep: availableActions.includes(ADD_VARIANT_STEP),
  //     editStep: availableActions.includes(EDIT_VARIANT_STEP),
  //     deleteStep: availableActions.includes(DELETE_VARIANT_STEP),
  //     updateOrder: availableActions.includes(UPDATE_ORDER),
  //     createVariant: availableActions.includes(CREATE_VARIANT),
  //     addChapter: availableActions.includes(ADD_CHAPTER_VARIANT),
  //     print: availableActions.includes(PRINT),
  //     editLocalAmendments: availableActions.includes(EDIT_LOCAL_AMENDMENT),
  //   }
  // } else {
    return {
      add: availableActions.includes(ADD),
      edit: availableActions.includes(EDIT),
      archive: availableActions.includes(ARCHIVE),
      move: availableActions.includes(MOVE),
      removeFromConfigurator: availableActions.includes(REMOVE_FROM_CONFIGURATOR),
      lock: availableActions.includes(LOCK),
      unlock: availableActions.includes(UNLOCK),
      translate:
        availableActions.includes(TRANSLATE) &&
        (!item.translated ||
          item.language === DEFAULT_LANGUAGE ||
          item.langauge !== currentLanguage),
      addStep: availableActions.includes(ADD_STEP),
      editStep: availableActions.includes(EDIT_STEP),
      deleteStep: availableActions.includes(DELETE_STEP),
      updateOrder: availableActions.includes(UPDATE_ORDER),
      // createVariant: availableActions.includes(CREATE_VARIANT),
      addChapter: availableActions.includes(ADD_CHAPTER),
      print: availableActions.includes(PRINT),
      editLocalAmendments: availableActions.includes(EDIT_LOCAL_AMENDMENT),
    }
  // }
}

export const getTagLevelKeys = (configuratorType) =>
  configuratorType === "n/a" ||
  configuratorType === null ||
  (configuratorType && configuratorType.toLowerCase() === GLOBAL_ACCOUNT)
    ? { levelA: GLOBAL_ACCOUNT, levelB: GLOBAL_ACCOUNT_COUNTRY, levelC: SITE }
    : { levelA: COUNTRY, levelB: ACCOUNT, levelC: SITE }

export const getTagLevelIntlKeys = (configuratorType) => {
  const keyValues = getTagLevelKeys(configuratorType)
  const intlPrefix = "common."
  const tagKeys = Object.keys(keyValues).reducer((accum, key) => ({
    ...accum,
    [key]: intlPrefix + camelCase(keyValues[key]),
  }))
  return tagKeys
}

export const getUserTagLevelKeys = createSelector(getConfiguratorType, getTagLevelKeys)

export const getUserTagLevelIntlKeys = createSelector(getUserTagLevelKeys, (roleTagKeys) => {
  const intlPrefix = "common."
  const tagKeys = Object.keys(roleTagKeys).reduce(
    (accum, key) => ({ ...accum, [key]: intlPrefix + camelCase(roleTagKeys[key]) }),
    {}
  )
  return tagKeys
})

export const getAllUserTags = createSelector(getUser, (user) => [
  ...user.levelATags,
  ...user.levelBTags,
  ...user.levelCTags,
])
