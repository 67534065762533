import { createAsyncAction, createAsyncActionWithContext } from "../utils"

import { api as externalApi } from "../../services/api"
import { createAction } from "redux-actions"
import types from "./types"

const api = externalApi()

export const fetchUserProfile = createAsyncAction(types.FETCH_PROFILE, api.user.getUserProfile)
export const checkAuthenticationStatus = createAsyncAction(
  types.CHECK_AUTH,
  api.user.checkAuthenticationStatus,
  () => ({ handled: true })
)
export const fetchRoles = createAsyncActionWithContext(types.FETCH_ROLES, api.user.getRoles)
export const fetchConfigurator = createAsyncActionWithContext(
  types.FETCH_CONFIGURATOR,
  api.configurator.getConfigurator
)
export const setAccount = createAction(types.SET_ACCOUNT)
export const setCountry = createAction(types.SET_COUNTRY)
export const setRole = createAction(types.SET_ROLE)
export const setSite = createAction(types.SET_SITE)
export const logOut = createAction(types.LOG_OUT)
