export default function bindSelectors(selectors) {
  return (state, ownProps) =>
    Object.keys(selectors).reduce((accum, key) => {
      const selector = selectors[key]
      if (typeof selector === "function") {
        accum[key] = selector(state, ownProps)
      } else {
        throw new TypeError(
          `Could not bind selectors: the value of the property '${key}' is not a function`
        )
      }
      return accum
    }, {})
}
