import types from "./types"

export const initialState = Object.freeze({
  componentName: null,
  componentProps: null,
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.OPEN: {
      return {
        ...state,
        componentName: action.payload.componentName,
        componentProps: action.payload.componentProps,
      }
    }
    case types.CLOSE:
      return initialState
    default:
      return state
  }
}
