import { compose, createStore } from "redux"

import { DEFAULT_LANGUAGE } from "../globals"
import createRootReducer from "./reducers"
import history from "../history"
import middleware from "./middleware"
import { persistStore } from "redux-persist"
import messages from "../locales/en.json"

const enhancers = []
const production =
  process.env.NODE_ENV === "production" && process.env.BUILD_CONFIGURATION === "Production"
if (production === false) {
  // only enable redux devtools in development environments
  // ? https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}

// compose middleware with devtools
const composedEnhancers = compose(middleware, ...enhancers)

const initialState = {
  intl: {
    defaultLocale: DEFAULT_LANGUAGE,
    locale: DEFAULT_LANGUAGE,
    messages,
  },
}

// connect root reducer to router middleware and apply persistence configuration for localStorage
const rootReducer = createRootReducer(history)
// TODO: fix this call
const store = createStore(rootReducer, initialState, composedEnhancers)

// enable persistance of state through client local storage
export const persistor = persistStore(store)
window.persistor = persistor
export default store
// #region application state flow
/**

                       API Calls
                           ▲
                           │
                           ▼
                        Action         Action(s)    ┌──────────────────────┐
           ┌────────▶  Creators  ───────────────────┼──▶  Root Reducer     │
           │                                        │ ╔═══╗╔═══╗╔═══╗╔═══╗ │
   ┌──────────────┐                                 │ ║   ║║   ║║   ║║   ║ │
   │    React     │                                 │ ║┏━━╩╩━━━╩╩━━━╩╩━━┓║ │
   │  Components  │                                 │ ║┃ Slice Reducers ┃║ │
   └──────────────┘                                 │ ║┗━━╦╦━━━╦╦━━━╦╦━━┛║ │
           ▲                                        │ ║   ║║   ║║   ║║   ║ │
           │                                        │ ╚═══╝╚═══╝╚═══╝╚═══╝ │
           │                                        └──────────┬───────────┘
           │                                                   │
           │                                                   ▼
           └────────── Selectors  ◀──────────────────  Application State

*/
// #endregion
