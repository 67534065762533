import { arrayToSlugObject, flattenOpfItems, mergeOpfItems } from "../items/utils"
import { isArray, isEmpty, mergeWith, uniq } from "lodash"

import localeTypes from "../locale/types"
import { produce } from "immer"
import types from "./types"
import unflattenTree from "src/store/utils/unflattenTree"

const initialState = Object.freeze({
  configurators: {},
  countries: [],
  globalAccounts: [],
  allDocuments: [],
  sites: [],
  availableCountries: [],
  availableAccounts: [],
  availableGlobalAccounts: [],
  availableSites: [],
  globalItemBySlug: {},
  allGlobalItems: [],
  fullyLoadedItems: [],
  statisticsLoading: false,
  mostRecent: null,
  totalProcedures: null,
  totalPolicies: null,
})

const itemToIdObj = (item) => ({ [item.id]: item })
const toIdObject = (accum, value) => ({ ...accum, ...itemToIdObj(value) })
const arrayToIdObj = (opfItems) => opfItems.reduce(toIdObject, {})

const getOpfDocumentChildrenRecursively = (opfDocument) => {
  if (!isEmpty(opfDocument.opfDocumentChildren)) {
    return [
      { ...opfDocument, hasChildren: true },
      ...opfDocument.opfDocumentChildren.flatMap(getOpfDocumentChildrenRecursively),
    ]
  }
  return [opfDocument]
}

export const flattenOpfDocuments = (opfDocuments) => {
  opfDocuments = isArray(opfDocuments) ? opfDocuments : [opfDocuments]
  return opfDocuments.flatMap(getOpfDocumentChildrenRecursively)
}

export default function reducer(state = initialState, action) {
  return produce(state, (configurator) => {
    switch (action.type) {
      case types.SET_RECENT_CONFIGURATOR:
        // reset data if most recent configurator has changed
        if (configurator.mostRecent !== action.payload) {
          configurator.fullyLoadedItems = []
          configurator.allGlobalItems = []
          configurator.globalItemBySlug = {}
          configurator.totalProcedures = null
        }
        configurator.mostRecent = action.payload
        break

      case types.FETCH_STATISTICS.REQUEST:
        configurator.statisticsLoading = true
        break

      case types.FETCH_STATISTICS.SUCCESS:
        configurator.totalProcedures = action.payload.totalProcedures
        configurator.totalPolicies = action.payload.totalPolicies
        configurator.statisticsLoading = false
        break

      case types.FETCH_USER_CONFIGURATORS.SUCCESS:
        if (action.payload.configurators) {
          configurator.configurators = arrayToIdObj(action.payload.configurators)
        }
        break

      case localeTypes.SET_LANGUAGE:
        configurator.configurators = {}
        configurator.fullyLoadedItems = []
        configurator.allGlobalItems = []
        configurator.globalItemBySlug = {}
        break

      case types.FETCH_AVAILABLE_COUNTRIES.SUCCESS:
        configurator.availableCountries = action.payload.countries
        break

      case types.FETCH_AVAILABLE_ACCOUNTS.SUCCESS:
        configurator.availableAccounts = action.payload.accounts
        break

      case types.FETCH_AVAILABLE_GLOBAL_ACCOUNTS.SUCCESS:
        configurator.availableGlobalAccounts = action.payload.globalAccounts
        break

      case types.FETCH_AVAILABLE_SITES.SUCCESS:
        configurator.availableSites = action.payload.sites
        break

      case types.FETCH_DOCUMENTS.SUCCESS:
      case types.FETCH_DOCUMENTS_FOR_RELATIONS.SUCCESS:
        configurator.allDocuments = flattenOpfDocuments(
          unflattenTree(action.payload.nodes, "opfDocumentChildren")
        )
        break

      case types.CREATE_CONFIGURATOR.SUCCESS:
      case types.UPDATE_CONFIGURATOR.SUCCESS:
        configurator.configurators = {
          ...configurator.configurators,
          ...itemToIdObj(action.payload.configurator),
        }
        break

      case types.FETCH_ITEMS.SUCCESS:
      case types.SAVE_ITEMS_FOR_CONFIGURATOR.SUCCESS:
        return addAndUpdateExistingItems(action, configurator)

      case types.FETCH_COUNTRY_ACCOUNTS.SUCCESS:
      case types.FETCH_GLOBAL_ACCOUNTS.SUCCESS:
      case types.FETCH_COUNTRIES.SUCCESS:
      case types.FETCH_ACCOUNTS.SUCCESS:
      case types.FETCH_SITES.SUCCESS: {
        // removal of unneeded properties for state object
        const { success, errorResponse, totalProcedures, ...rest } = action.payload
        return {
          ...configurator,
          ...rest,
        }
      }
      default:
        return configurator
    }
    return configurator
  })
}

function addAndUpdateExistingItems(action, configurator) {
  const request = action.meta.request
  const requestedItem = request.opfItem || null
  const flattenedData = flattenOpfItems(action.payload.opfItems)
  const allSlugs = flattenedData.map((item) => item.slug)
  const newItemsObj = arrayToSlugObject(flattenedData)
  configurator.globalItemBySlug = mergeWith(
    configurator.globalItemBySlug,
    newItemsObj,
    mergeOpfItems
  )
  configurator.allGlobalItems = uniq([...configurator.allGlobalItems, ...allSlugs])
  if (requestedItem) {
    if (requestedItem.level > 5 && requestedItem.opfId) {
      configurator.fullyLoadedItems.push(requestedItem.opfId)
    }
  }
  return configurator
}
