import en from "../../locales/en.json"
import enGB from "antd/lib/locale-provider/en_GB"
import { produce } from "immer"
import types from "./types"

const { SET_LANGUAGE, FETCH_LANGUAGES, SET_ANTD_LOCALE } = types

export const initialState = Object.freeze({
  en,
  currentLanguage: "en",
  antd: enGB,
})

export default function reducer(state = initialState, action) {
  return produce(state, (locale) => {
    switch (action.type) {
      case SET_ANTD_LOCALE:
        locale.antd = action.payload
        break
      case FETCH_LANGUAGES.SUCCESS:
        locale.languages = action.payload.languages
        break
      case SET_LANGUAGE:
        locale.currentLanguage = action.payload
        locale.currentLanguageCached = action.payload
        break
      default:
        return locale
    }
  })
}
