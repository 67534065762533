import { DEFAULT_LANGUAGE, FRONTPAGE, SUPPORTED_LANGUAGES } from "../../globals"
import { api as externalApi } from "../../services/api"
import { createAction } from "redux-actions"
import { createAsyncActionWithContext } from "../utils"
import languages from "langs"
import { loadLocaleSettings } from "../../services/internationalization"
import moment from "moment"
import types from "./types"
import { updateIntl } from "react-intl-redux"
import history from "src/history"
import { getAnyActiveForms } from "src/store/ui/selectors"
import { getFormNames } from "redux-form"

const api = externalApi()

export const fetchLanguages = createAsyncActionWithContext(
  types.FETCH_LANGUAGES,
  api.locale.getLanguages
)
export const setAntdLocale = createAction(types.SET_ANTD_LOCALE)

export const loadDefaultLanguage = () => (dispatch, getState) => {
  const { intl, locale } = getState()
  const messages = locale[intl.defaultLocale]
  import("antd/lib/locale-provider/en_GB").then((module) => dispatch(setAntdLocale(module.default)))
  moment.locale(intl.defaultLocale)
  dispatch({ type: types.SET_LANGUAGE, payload: intl.defaultLocale })
  return dispatch(updateIntl({ locale: intl.defaultLocale, messages }))
}

/** changes the current language, fetches the given locale if it is supported and not stored in state */
export const changeLanguage = (languageCode) => (dispatch, getState) => {
  const state = getState()
  const { locale, intl } = state
  const defaultLanguage = locale[intl.defaultLocale]
  const currentLanguage = locale.currentLanguage
  if (languageCode === currentLanguage) return Promise.resolve()
  // don't change language if it is the same as the one currently in the store
  if (!languageCode || languageCode === defaultLanguage) {
    return dispatch(loadDefaultLanguage())
  }
  if (SUPPORTED_LANGUAGES.includes(languageCode)) {
    dispatch({ type: types.SET_LANGUAGE, payload: languageCode || intl.defaultLocale })
    return loadLocaleSettings(languageCode)
      .then(({ locale, antdLocale }) => {
        const messages = { ...defaultLanguage, ...locale }
        if (antdLocale) dispatch(setAntdLocale(antdLocale))
        if (messages) return dispatch(updateIntl({ locale: languageCode, messages }))
      })
      .catch((error) => {
        console.log(error)
        dispatch(loadDefaultLanguage())
      })
  } else {
    // load default if not supported
    dispatch({ type: types.SET_LANGUAGE, payload: languageCode || intl.defaultLocale })
    const languageExists = !!locale.languages.find((language) => language.code === languageCode)
    if (locale.currentLanguage !== intl.defaultLanguage && languageExists === false) {
      return Promise.resolve(dispatch(loadDefaultLanguage()))
    }
  }
}

export const getPathWithLocale = (locationObject, language) => {
  const { pathname, search } = locationObject
  const pathOrFrontpage = pathname === "/" ? `/${FRONTPAGE}` : pathname

  let path
  if (!language || language.includes(DEFAULT_LANGUAGE)) {
    path = `${pathOrFrontpage}${search}`
  } else {
    path = `/${language}${pathOrFrontpage}${search}`
  }
  return path.toLowerCase()
}

export const changeRouteWithPrompt = (path, unsavedChangesMessage) => (dispatch, getState) => {
  const state = getState()
  const anyActiveForms = getAnyActiveForms(state)
  if (anyActiveForms) {
    const activeForms = getFormNames()(state)
    return new Promise((resolve, reject) => {
      if (window.confirm(unsavedChangesMessage)) {
        if (activeForms) {
          dispatch(setProcedureEditMode(false))
          dispatch(destroy(...activeForms))
        }
        resolve(history.push(path))
      } else resolve()
    })
  }
  return Promise.resolve(history.push(path))
}

/** change the current url to contain the given language code
 *  and removes it if the given code is the default langauge */
export const changeLanguageRoute = (languageCode, unsavedChangesMessage) => (dispatch) => {
  if (languageCode === null) return Promise.resolve()
  const location = window.location
  const pathSnippets = location.pathname.split("/")
  const firstPath = pathSnippets[1]

  const alreadyHasLanguageCode = firstPath
    ? languages.has("1", firstPath.split("-")[0] || "")
    : false

  let path
  if (alreadyHasLanguageCode) {
    const pathWithoutLang = pathSnippets
      .filter((path) => path !== firstPath)
      .join("/")
      .toLowerCase()
    path = getPathWithLocale({ search: location.search, pathname: pathWithoutLang }, languageCode)
  } else {
    path = getPathWithLocale(location, languageCode)
  }

  if (path !== location.pathname + location.search) {
    dispatch(changeRouteWithPrompt(path, unsavedChangesMessage))
  }
  return dispatch(changeLanguage(languageCode))
}
