import { CancelToken, isCancel } from "axios"
import { createAction } from "redux-actions"

import bindActions from "./bindActions"

/**
                            ┌────────────────────┐
                            │ createAsyncAction  │
                            └────────────────────┘
                                       │
                                       │
        ┌──────────────────┐           │           ┌─────────────────┐
        │  Async Function  │◀──────────┴──────────▶│  Types Object   │  redux-action-types-creator
        └─────────┬────────┘                       ├─────────────────┤
                  │                                │                 │
      ┌───────────▼──────────┐   dispatch(action)  │ ┌─────────────┐ │
      │ before function call │─────────────────────┼▶│   REQUEST   │ │
      └───────────┬──────────┘                     │ └─────────────┘ │
                  │                                │                 │
      ┌───────────▼──────────┐   dispatch(action)  │ ┌─────────────┐ │
      │ call async function  │           ┌─────────┼▶│   FAILURE   │ │
      │     with payload     │           │         │ └─────────────┘ │
      ├────────────┬─────────┤           │         │                 │
      │  Response  │ != 200  │───────────┘         │ ┌─────────────┐ │
      │   code:    ├─────────┤           ┌─────────┼▶│   SUCCESS   │ │
      │            │   200   │───────────┘         │ └─────────────┘ │
      └────────────┴─────────┘   dispatch(action)  └─────────────────┘

*/

/**
 * async action creator function. For integrating async actions with the redux thunk middleware in a seamless way.
 * @param {any} type redux action type
 * @param {function} asyncFunction asynchronous action to execute
 * @param {function} [metaCreator]
 */
export default function createAsyncAction(type, asyncFunction, metaCreator) {
  if (type === undefined) throw TypeError("type parameter cannot be undefined")
  if (typeof asyncFunction !== "function")
    throw TypeError("asyncFunction parameter is not of type function")

  return (payload) => (dispatch) => {
    const customMeta = typeof metaCreator === "function" ? metaCreator(payload) : {}
    const source = CancelToken.source()
    const meta = { request: payload, ...customMeta }

    const { REQUEST, SUCCESS, FAILURE } = type
    const { request, failure, success } = bindActions({
      request: createAction(REQUEST, null, () => meta),
      failure: createAction(FAILURE, null, () => ({ ...meta, error: true })),
      success: createAction(SUCCESS, null, () => meta),
    })(dispatch)

    request(payload)
    const executedRequest = asyncFunction(payload, source.token)
    const handledRequest = executedRequest.then(
      (response) => {
        if (response.data) {
          success(response.data)
          return Promise.resolve(response.data)
        } else {
          failure(response.statusText)
          return Promise.reject(response)
        }
      },
      (thrown) => {
        if (!isCancel(thrown)) {
          failure(thrown)
          return Promise.reject(thrown)
        }
      }
    )
    handledRequest.cancel = source.cancel
    return handledRequest
  }
}
