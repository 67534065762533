import { api as externalApi } from "../../services/api"
import { createAsyncActionWithContext } from "../utils"
import types from "./types"

const api = externalApi()

export const fetchMyDrafts = createAsyncActionWithContext(
  types.FETCH_MY_DRAFTS,
  api.drafts.getMyDrafts
)
